<div class="row row_main">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Facturas de Simpli</h1>
    <small class="subtitulo-simpli">Listado de facturas emitidas</small>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 40px;" *ngIf="facturas.length > 0" >
    <mat-card class="card-home" >
      <mat-card-content >
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input matInput (keyup)="applyFilter($event)"  #input>
          </mat-form-field>
        </div>

        <div class="add_factoring">
          <table mat-table [dataSource]="dataSource" style="width: 100%;">
            <ng-container matColumnDef="documento">
              <th mat-header-cell *matHeaderCellDef style="text-align: justify !important;">Factura</th>
              <td mat-cell *matCellDef="let element" style="text-align: justify !important;min-width: 130px;">
                <input value="{{ element.archivo  }}" style="text-align: justify;" class="no-input" readonly>
                </td>
            </ng-container>
  
            <ng-container matColumnDef="fecha_creacion">
              <th mat-header-cell *matHeaderCellDef>Fecha Creación</th>
              <td mat-cell *matCellDef="let element" style="min-width: 130px;">
                <input value="{{ element.date_created| date: 'dd/MM/yyyy' | titlecase }}" readonly class="no-input"> 
              </td>
            </ng-container>
  
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef style="text-align: center !important;">Descargar</th>
              <td mat-cell *matCellDef="let element" style="min-width: 100px;">
                <a href="{{ element.ruta }}" target="_blank" matTooltip="Descargar" class="boton-descargar download"
                  style="cursor: pointer" download="{{ element.ruta }}">
                  <i class="material-icons" style="font-size: 22px; margin-top: 2px;color:#9c9c9c;">
                    file_download
                  </i>
                </a>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator class="paginator__container" [pageSizeOptions]="pageSizes" pageSize="20" showFirstLastButtons>
          </mat-paginator>
        </div>


      </mat-card-content>

    </mat-card>
  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="this.noFacturas == false && show_spinner_loading == false">
    <!--EMPTY-->
    <div class="row animate__animated animate__fadeIn">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster">

        <div class="empty-msg" style="height: 300px;">

          
          <div style="padding-top: 50px;text-align: center;">
            <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-print-magnifying-glass"></i>
          </div>

          <div style="padding-top: 50px;text-align: center;">
            <mat-label class="label-bienvenida">
              <div class="label-empty">
                
                  No hay información de facturas emitidas. <br><br>Si tienes dudas o preguntas contacta a tu ejecutivo. 
              </div>



            </mat-label>
          </div>


        </div>


  </div>

    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="show_spinner_loading == true && noFacturas == false">
    <div class="row animate__animated animate__fadeIn" >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">

          <div class="container-loading">
      
              
              <div class="img-loading">
              <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
              </div>
      
              <div style="margin-top: 20px;">
              <mat-label class="label-loading">
                  <div>La información está siendo procesada.</div>
                  <div>Esto no tomará mucho tiempo.</div>
              </mat-label>
              </div>
      
          </div>
      
      
      </div>
    </div>
  </div>





</div>