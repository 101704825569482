import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;


declare var $ :any;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.less']
})
export class PasswordComponent implements OnInit {
  
  disabledButton = true;
  showSpinner = false
  showSpinnerMini = false;
  hide = true;
  dis_new = true;

  valForm: FormGroup;

  open_as_dialog:boolean=false;

  constructor(public snackBar: MatSnackBar, fb: FormBuilder, private router: Router, public dialog: MatDialog,  
    public settings: SettingsService, private dialogRef:MatDialogRef<PasswordComponent>) 
  { 
    let new_password = new FormControl('', Validators.compose([Validators.required,Validators.minLength(6)]));
    let cPassword = new FormControl(null, Validators.compose([Validators.required, CustomValidators.equalTo(new_password)]));

    this.valForm = fb.group({
      'password': ['',  Validators.compose([Validators.required])],
      'new_password': new_password,
      're_new_password': cPassword,
    });

    
  }

  ngOnInit() {
    this.valForm.controls["new_password"]["disable"]();
    this.valForm.controls["re_new_password"]["disable"]();
  }

  async submitForm($ev:any, value: any) {

    $ev.preventDefault();

    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      try {

        this.showSpinner= true;
        this.disabledButton = true;

        const bd: any = await this.settings.get_query(3, 'change-password/', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 201:
          case 200:
  
  
          this.disabledButton = false;
          this.showSpinner= false;

          this.snackBar.open("¡Tu contraseña fue actualizada!", "Aceptar", {
            duration: 3000,
          });

          this.dialogRef.close()
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
        this.disabledButton = false;
        this.showSpinner= false;

        this.valForm.controls["new_password"].setValue("");
        this.valForm.controls["re_new_password"].setValue("");
        this.valForm.controls["password"].setValue("");

        this.snackBar.open("Tu Contraseña no corresponde, intentalo de nuevo", "Aceptar", {
          duration: 3000,
        });
      }
    }else{
      console.log("no se esta llamadno nada")
    }

  }

  async verify_password() {
    try {

      let value = {"password": this.valForm.controls["password"].value}
      this.showSpinnerMini = true;

      const bd: any = await this.settings.get_query(2, 'verify-password/', value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.valForm.controls["new_password"]["enable"]();
            this.valForm.controls["re_new_password"]["enable"]();
            this.showSpinnerMini = false;
            this.disabledButton = false;

            let sb = this.snackBar.open("Ingresa tu nueva contraseña", "Aceptar", {
              duration: 3000,
              panelClass: ['simpli-snack']
            });


          break;
        default:

          break;
      }
    } catch (bd: any) {
        this.showSpinnerMini = false;
        this.disabledButton = true;
        let sb = this.snackBar.open("La contraseña ingresada no es correcta.", "Aceptar", {
          duration: 2000,
          panelClass: ['simpli-snack']
        });

        this.valForm.controls["new_password"]["disable"]();
        this.valForm.controls["re_new_password"]["disable"]();
        this.valForm.controls["new_password"].setValue("");
        this.valForm.controls["re_new_password"].setValue("");
    }
  }

}
