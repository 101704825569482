<mat-card>
    <mat-card-content>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;">
            <h1 class="titulo-simpli">Documentos</h1>
            <small class="subtitulo-simpli">Carga tus documentos</small>
        </div>

        <div class="row" [@.disabled]="disableAnimation">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-top: 20px;">

                <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                        <mat-card style="border: none;box-shadow: none;">

                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;text-align: right;margin-bottom: 10px;margin-top: 10px;">
                                <small class="subtitulo-simpli">Documentos Financieros</small>
                            </div>
                                
                            <mat-card-content style="padding:0 !important;min-height: 0px !important;">
                                <mat-accordion expanded="false">
                                    <span #parent_files_financieros></span>
                                </mat-accordion>
                    
                    
                            </mat-card-content>
                        </mat-card>
                    
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top" >
                        <mat-card style="border: none;box-shadow: none;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;;text-align: right;margin-bottom: 10px;margin-top: 10px;">
                                <small class="subtitulo-simpli">Documentos Legales</small>
                            </div>
                        
                        <mat-card-content style="padding:0 !important;">
                        <mat-accordion expanded="false">
                            <span #parent_files_legales></span>
                        </mat-accordion>
                        
                        </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                        <mat-card style="border: none;box-shadow: none;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;;text-align: right;margin-bottom: 10px;margin-top: 10px;">
                                <small class="subtitulo-simpli">Otros Documentos</small>
                            </div>
                                
                            <mat-card-content style="padding:0 !important;min-height: 0px !important;">
                                <mat-accordion expanded="false">
                                    <span #parent_files_otros></span>
                                </mat-accordion>
                    
                    
                            </mat-card-content>
                        </mat-card>
                    </div>

                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>



