<div mat-dialog-title>

      <h1 class="titulo-simpli">Mis Credenciales</h1>
      <small class="subtitulo-simpli"><input class="no-input nombre-empresa" value="{{business_name}}"></small>

</div>


<div mat-dialog-content class="animate__animated animate__fadeIn">

    <mat-card style="margin-top: 20px;border-radius: 10px;box-shadow: none !important;padding-right: 0;padding-left: 0;" >
        <mat-card-content>

            <form [formGroup]="valForm">

                <div class="row">

                    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <mat-form-field appearance="fill" color="primary" style="position: relative;">
                            <mat-label>Clave SII</mat-label>
                            <input matInput formControlName="clave_sii" type="password" readonly  (click)="change_sii()">
                            <mat-icon matSuffix *ngIf="status_clave == true && loading == false" class="material-icons icon-sii">
                                check_circle
                            </mat-icon>
                            <mat-icon matTooltip="Clave no cargada, o la clave ya no es válida." matSuffix *ngIf="status_clave == false && loading == false" class="material-icons icon-sii" style="color:gray;cursor: pointer;">
                                sync_problem
                            </mat-icon>
                            <mat-spinner color="primary" style="margin-right: 10px;" *ngIf="loading == true" diameter="20" matSuffix></mat-spinner>
                            <mat-hint>Cargar clave SII</mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="text-align: center;">
                        <button class="primary-button"  mat-flat-button style="margin-top: 20px;" (click)="change_sii()">Editar</button>
                    </div>
        
                </div>
    
                <div class="row" style="margin-top: 15px;" *ngIf="es_mi_pyme == true">

                    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <mat-form-field appearance="fill" color="primary">
                            <mat-label>Certificado Digital</mat-label>
                            <input matInput formControlName="certificado_sii" style="position: relative;" readonly (click)="change_certificado()">
                            <mat-icon matSuffix *ngIf="success_certificado == true" class="icon-sii">check_circle</mat-icon>
                            <mat-icon  matTooltip="Requiere completar información" *ngIf="requiere_completar == true" matSuffix style="color:gray;">error_outline</mat-icon>
                            <mat-hint *ngIf="fecha_vencimiento == null">Carga tu Certificado Digital</mat-hint>
                            <mat-hint *ngIf="fecha_vencimiento != null">Fecha de Vencimiento: {{ fecha_vencimiento | date:'dd/MM/yy' }}</mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="text-align: center;">
                        <button class="primary-button" mat-flat-button style="margin-top: 20px;" (click)="change_certificado()">Editar</button>
                    </div>

        
                </div>
        
                <div class="row" style="margin-top: 15px;">

                    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <mat-form-field appearance="fill" color="primary">
                            <mat-label>Facturador Electrónico</mat-label>
                            <input matInput formControlName="facturador" style="position: relative;" readonly (click)="change_facturador()">
                            <mat-icon matSuffix *ngIf="success_facturador == true" class="icon-sii">check_circle</mat-icon>
                            <mat-hint>Indicanos tu Facturador Electrónico</mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="text-align: center;">
                        <button class="primary-button" mat-flat-button style="margin-top: 20px;" (click)="change_facturador()">Editar</button>
                    </div>
        
                </div>

                <div class="row animate__animated animate__fadeIn" *ngIf="es_mi_pyme == false">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <div style="margin-top: 30px;padding: 5px;">

                            <small style="font-size: 14px;">
                                Tu sistema de facturación aún no se enlaza con nosotros, por ahora no podrás usar el 100% de las funciones de nuestra plataforma.
                                Revisa lo que si podrás realizar en nuestra plataforma  <strong style="text-decoration: underline;cursor:pointer;" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover" #popoverTrigger="mdePopoverTrigger"> aqui!</strong>
                                
                                
                                    <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                                        <mat-card style="padding:25px">
                
                                        <ol style="padding-left: 0;font-size: 14px;">
                                            <li>Consultar facturas vigentes</li>
                                            <li>Revisar el estado de tu cartera</li>
                                            <li>Realizar simulaciones</li>
                                            <li>Ver tus excedentes</li>
                                        </ol>
                
                                        </mat-card>
                                    </mde-popover>
                            </small>

                        </div>

                    </div>
                </div>

            </form>

        </mat-card-content>

    </mat-card>


</div>

<div mat-dialog-actions>
        <button class="secondary-button"  mat-flat-button mat-dialog-close>
            Cerrar
        </button>
        <button class="primary-button"  mat-flat-button mat-dialog-close>
            Finalizar
        </button>

</div>




