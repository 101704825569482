
<div class="row animate__animated animate__fadeIn">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <h1 class="titulo-simpli titulo-simpli-responsive">Completa el proceso de simulación</h1>

  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

    <div class="row">

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

          <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12">
            <mat-card id="card-simulation" class="card-tabla">

                <!--STEPPER CONTROL-->
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-responsive" style="margin-top: 40px;">

                  <div class="container-stepper-simpli">

                    <div id="step-simpli1" class="stepper-simpli stepper-simpli-active" (click)="change_step('step-simpli1')">
                      <i class="fa-regular fa-pencil"></i>
                      <div class="step-text">Vencimiento</div>

                      <div class="line-step-simpli"></div>
                    </div>
                    


                    <div id="step-simpli2" class="stepper-simpli" (click)="change_step('step-simpli2')">
                      <i class="fa-regular fa-memo"></i>
                      <div class="step-text">Resumen</div>

                    </div>



                  </div>

                </div>


                <div *ngIf="step == 1 && set_vencimiento == 0 " class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

                  <div style="text-align: center;">
                    <mat-label class="l-i">Monto a simular:</mat-label>
                    <mat-label class="l-d" style="position: relative;">$ {{formatMoney(monto_total)}} <span style="margin-left: 10px; position: absolute;margin-top: -3px;" matTooltip="El monto a simular es el resultado de la suma de los montos de cada factura agregada a la simulación." matSuffix class="material-icons-outlined">
                      info
                    </span></mat-label>
                  </div>

                </div>

                <div *ngIf="step == 1 && set_vencimiento == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">
                  <form  [formGroup]="valFormFilter" style="margin-top: 30px;">

                
                    <mat-form-field appearance="fill" style="position: relative;width: 90%;">
                      <mat-label>Porcentaje de financiamiento</mat-label>
                      <input matInput min="0" max="100" type="number" formControlName="anticipo" (change)="alert_anticipo()">
                      <mat-hint>Asigna el porcentaje de financiamiento</mat-hint>
                      <mat-error *ngIf="valFormFilter.controls['anticipo'].hasError('required')">
                        Este campo es <strong>obligatorio</strong>
                      </mat-error>
                      <mat-icon matSuffix class="material-icons">
                        percent
                      </mat-icon>
                      <span style="position: absolute;margin-left: 10px;top:11px" matTooltip="El 95% es establecido considerando las condiciones de solicitud de línea. El aumento de este porcentaje, estará sujeto a evaluación." matSuffix class="material-icons-outlined info-icon">
                        info
                      </span>
                    </mat-form-field>


                    <mat-form-field appearance="fill" style="width: 90%;">
                      <mat-label>Tipo de vencimiento</mat-label>
                      <mat-select formControlName="tipo_vencimiento" (selectionChange)="statuschange($event)">

                        <!--fecha de vencimiento, muchas facturas-->
                        <mat-option *ngIf="!single_factura" value="1">Asignar misma fecha de vencimiento</mat-option>
                        <mat-option *ngIf="!single_factura" value="3">Asignar misma cantidad de días</mat-option>

                        <mat-option *ngIf="!single_factura" value="2">Asignar distintas fechas de vencimiento</mat-option>
                        <mat-option *ngIf="!single_factura" value="4">Asignar cantidad de días por factura</mat-option>


                        <!--opciones de 1 sola factura-->
                        <mat-option value="6" *ngIf="single_factura">Asignar fecha de vencimiento</mat-option>
                        <mat-option value="5" *ngIf="single_factura">Asignar días de vencimiento</mat-option>


                      </mat-select>
                      <mat-hint>Selecciona el tipo de vencimiento para tus facturas</mat-hint>
                      <mat-error *ngIf="valFormFilter.controls['tipo_vencimiento'].hasError('required')">
                        Este campo es <strong>obligatorio</strong>
                      </mat-error>
                    </mat-form-field>

                    <!--6-->
                    <mat-form-field appearance="fill"  (click)="picker.open()" *ngIf="(selected == 6 || selected == 1) && single_factura == true || (selected == 6 || selected == 1) && single_factura == false" style="width: 90%;">
                      <mat-label>Fecha de vencimiento</mat-label>
                      <input type="text" [min]="minDate" matInput [matDatepicker]="picker" (dateChange)="fecha_todas($event)" formControlName="fecha_vencimiento_utc">
                      <mat-hint>Asigna la fecha de vencimiento para tus facturas  </mat-hint>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="valFormFilter.controls['fecha_vencimiento_utc'].hasError('invalid')">
                        Este campo es <strong>obligatorio</strong>
                      </mat-error>
                    </mat-form-field>

                    <!--5-->
                    <mat-form-field appearance="fill" *ngIf="(selected == 5 || selected == 3) && single_factura == true || (selected == 5 || selected == 3) && single_factura == false" style="width: 90%;">
                      <mat-label>Cantidad de días</mat-label>
                      <input matInput min="1" type="number" formControlName="dias_vencimiento" (change)="capturar_fecha_por_dias($event)">
                      <mat-hint>Asigna la cantidad de días de vencimiento</mat-hint>
                      <mat-error  *ngIf="valFormFilter.controls['dias_vencimiento'].hasError('invalid')">
                        Este campo es <strong>obligatorio</strong>
                      </mat-error>
                    </mat-form-field>
                
                

                
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                
                        <div class="btn-simpli-container">
                          <button class="primary-button" type="button" mat-flat-button (click)="submitForm($event, valFormFilter.value)">
                            Continuar
                          </button>

                          <button class="secondary-button" type="button"  mat-flat-button (click)="simular()">
                            Volver
                          </button>
                        </div>
                        
                      </div>
                    </div>
                
                
                  </form>
                </div>

                <div *ngIf="set_vencimiento == 1 || set_vencimiento == 2" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

                  <div style="text-align: center;">
                    <h1 *ngIf="set_vencimiento == 1" class="title-step2">Asigna una fecha para cada factura</h1>
                    <h1 *ngIf="set_vencimiento == 2" class="title-step2">Asigna una cantidad de días para cada factura</h1>
                    <mat-label class="l-i">Monto a simular:</mat-label>
                    <mat-label class="l-d" style="position: relative;">$ 1.600.000 <span style="margin-left: 10px; position: absolute;margin-top: -3px;" matTooltip="El monto a simular es el resultado de la suma de los montos de cada factura agregada a la simulación." matSuffix class="material-icons-outlined">
                      info
                    </span></mat-label>
                  </div>

                </div>

                <div *ngIf="set_vencimiento == 1" class="col-lg-11 col-md-11 col-sm-11 col-xs-12 col-top animate__animated animate__fadeIn" style="margin: 0 auto;">

                  <div class="scroll-container add_factoring">
                    <div class="container-facturas" *ngFor="let i of facturas; let index=index">
                      <div class=row>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="N° Factura:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.folio}}"></div>
                          </div>

                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Deudor:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.deudor}}"></div>
                          </div>

                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Monto:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="${{formatMoney(i.monto)}}"></div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: center;margin-top: 5px;">

                          <div class="container-vencimiento l-vencimiento" style="text-align: justify;">Asigna la fecha de vencimiento</div>

                          <div [ngClass]="{'container-vencimiento':true}">
                            
                              <mat-form-field appearance="fill"  (click)="picker.open()" style="width: 100%;">
                                <mat-label>Fecha de vencimiento</mat-label>
                                <input type="text" readonly matInput [matDatepicker]="picker" [min]="minDate" (dateChange)="fecha_individual($event, index)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button class="primary-button" mat-flat-button (click)="validate_date()">
                          Continuar
                        </button>
                      </div>
                      
                    </div>
                  </div>

                </div>

                <div *ngIf="set_vencimiento == 2" class="col-lg-11 col-md-11 col-sm-11 col-xs-12 col-top animate__animated animate__fadeIn" style="margin: 0 auto;">

                  <div class="scroll-container add_factoring">
                    <div class="container-facturas" *ngFor="let i of facturas; let index=index" >
                      <div class=row>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="N° Factura:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.folio}}"></div>
                          </div>

                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Deudor:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="{{i.deudor}}"></div>
                          </div>

                          <div class="row divrow">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"><input class="no-input lab-i" readonly value="Monto:"></div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input class="no-input lab-d" readonly value="${{formatMoney(i.monto)}}"></div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="text-align: center;margin-top: 5px;">

                          <div class="container-vencimiento l-vencimiento" style="text-align: justify;width: 90%;">Asigna la cantidad de días de vencimiento</div>

                          <div style="width: 90%;" [ngClass]="{'container-vencimiento':true}">
                            

                              <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>Cantidad de días</mat-label>
                                <input matInput min="1" type="number" (change)="dias_individual($event, index)">
                              </mat-form-field>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button class="primary-button" mat-flat-button (click)="validate_date()">
                          Continuar
                        </button>
                      </div>
                      
                    </div>
                  </div>

                </div>


                <!--STEP RESUMEN-->
                <div *ngIf="step == 2 && set_vencimiento == 0" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn">

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa animate__animated animate__fadeIn" *ngIf="cantidad_facturas <= 7">

                        <h1 class="titulo-interno">Facturas simuladas</h1>

                        <div class="contenedor-facturas add_factoring">
  
                          <div class="forfacturas" *ngFor="let i of facturas; let index=index">

                                <div class="rowfacturas2">
                                  <div class="label-facturas">N° Factura {{index+1}}:</div>
                                  <div class="strong-facturas">{{i.folio}}</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="label-facturas" style="margin-left: 50px;">Monto total:</div>
                                  <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(i.monto)}}</div>
                                </div>

                          </div>
  
                        </div>

                      </div>

                      <div class="caja-rosa animate__animated animate__fadeIn" *ngIf="cantidad_facturas > 7">

                        <h1 class="titulo-interno">Facturas agregadas</h1>

                        <div style="margin-top: 25px;">
  
                          <div>

                              <div class="label-many">
                                Para visualizar mejor los datos, abre el detalle.
                              </div>

                              <div>
                                <mat-label class="pink-label" (click)="detalle_factura()">Abrir detalle <i class="fa-solid fa-angle-right"></i></mat-label>
                              </div>

                          </div>
  
                        </div>

                      </div>

                      <div class="caja-rosa animate__animated animate__fadeIn" style="padding-left: 0px;" *ngIf="cantidad_facturas == 0">

                        <div style="text-align: center;margin-top: 20px;margin-bottom: 20px;">
                          <i style="color: gray; font-size: 25px;" class="fa-solid fa-spinner fa-spin-pulse"></i>
                        </div>

                      </div>


                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa">

                        <h1 class="titulo-interno">Montos asociados</h1>

                        <div class="contenedor-sim add_factoring">
  
                          <div class="forfacturas" >

                                <div class="rowfacturas2">
                                  <div class="label-facturas">Monto total a simular:</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.total)}}</div>
                                </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">% Financiamiento:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{simulation.anticipo}}%</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Monto de anticipo:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.valor_anticipo_total)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Vencimiento: </div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{simulation.dias_promedio}} días</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Tasa de interés:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">{{simulation.tasa_interes}}%</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Monto total de interés:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.interes_total)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Comisión:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.comision)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Gastos operacionales:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.gastos)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Diferencia de precios:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.interes_total)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">IVA:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.iva_operacion)}}</div>
                            </div>

                          </div>

                          <div class="forfacturas" >

                            <div class="rowfacturas2">
                              <div class="label-facturas">Excedentes:</div>
                            </div>

                            <div class="rowfacturas2">
                              <div class="strong-facturas" style="margin-left: 50px;">${{formatMoney(simulation.excedentes)}}</div>
                            </div>

                          </div>
  
                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                      <div class="caja-rosa" style="padding-top: 5px;padding-bottom: 5px;">

                          <div class="forfacturas" style="margin-bottom: 0px;">

                                <div class="rowfacturas2">
                                  <div class="label-facturas2">Monto líquido</div>
                                </div>

                                <div class="rowfacturas2">
                                  <div class="strong-facturas2" style="margin-left: 50px;">${{formatMoney(simulation.monto_recibir)}}</div>
                                </div>

                          </div>
  
                      </div>

                    </div>

                  </div>


                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              
                      <div class="btn-simpli-container">
                        <button [disabled]="wait_to_send" class="primary-button" mat-flat-button (click)="alert_success()">
                          <mat-label *ngIf="wait_to_send==false">Finalizar</mat-label>
                          <span *ngIf="wait_to_send==true"><i style="color: white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                        </button>

                        <button [disabled]="loading_pdf_" class="secondary-button" mat-flat-button (click)="download_pdf()">
                          <mat-label *ngIf="loading_pdf_ ==false">Descargar</mat-label>
                          <span *ngIf="loading_pdf_ ==true"><i style="color: white;" class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                        </button>
                      </div>
                      
                    </div>
                  </div>
                </div>



            </mat-card>
          </div>

          <div class="col-lg-3 col-md-2 col-sm-1 col-xs-12"></div>

    </div>

  </div>

</div>