<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" >


  <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top cajaGrande" style="margin: 0 auto;box-shadow: none !important;border:none !important">


        <div>
            <img style="margin-bottom: 20px;" src="../../../../assets/simpli/cesion/unnamed.gif" height="165px">
        </div>


        <div class="row" style="margin-top: 40px; display: none;">

            <div style="margin-top:50px;width: 100%;">
                <button  mat-flat-button class="primary-button" type="button" (click)="close_dialog()" style="margin-top: 10px;">Cerrar</button>
            </div>

        </div>


      </div>

  </div>

</div>
