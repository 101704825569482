<div class="row row_main" id="start_solicitud">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli" style="font-weight: 700 !important;">¿Qué necesitas hacer hoy?</h1>
      </div>
    </div>
  </div>
  
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_excel" class="neumorphism2 excelbox" (click)="switch_case(1)" 

        joyrideStep="simular" [stepContent]="simular"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-list-check excelicon"></i>
          <br>
          <small>Simula tu operación</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_nomina" class="neumorphism2 nominabox" (click)="switch_case(2)" joyrideStep="confpp" [stepContent]="confpp" [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-file-signature nominaicon"></i>
          <br>
          <small>Confirming & PP</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_prove" class="neumorphism2 provebox" (click)="switch_case(6)"
        joyrideStep="pp" [stepContent]="pp"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-dollar-sign proveicon"></i>
          <br>
          <small>Paga proveedores</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_operacion" class="neumorphism2 operacionbox" (click)="openDialog()"
        joyrideStep="credenciales" [stepContent]="credenciales"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-user-lock operacionicon"></i>
          <br>
          <small>Mis credenciales</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_calendar" class="neumorphism2 operacionbox" routerLink="/home/operaciones"
        joyrideStep="operaciones" [stepContent]="operaciones"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
        <i class="fa-light fa-ballot-check operacionicon"></i>
          <br>
          <small style="display: block;margin-top: 9px;">Operaciones</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top" style="display: none;">
        <div id="caja_linea" class="neumorphism2 lineabox" (click)="switch_case(5)"
        joyrideStep="stats" [stepContent]="stats"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-chart-column lineaicon"></i>
          <br>
          <small>Revisa tu resumen de línea</small>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 col-top">
        <div id="caja_cartola" class="neumorphism2 cartolabox" (click)="switch_case(7)" 
        joyrideStep="cartola" [stepContent]="cartola"
        [doneTemplate]="doneButton"     
        [prevTemplate]="prevButton"
        [nextTemplate]="nextButton" (prev)="to_top()" (next)="to_top()"  (done)="onDone()">
          <i class="fa-light fa-chart-line cartolaicon"></i>
          <br>
          <small>Cartola de movimientos</small>
        </div>
      </div>

    </div>
  </div> 
  
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row p-custom">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_mensaje_inspirador == true">

        <div style="width: 100%;">
          <div class="container-bienvenida">
            <app-bienvenida #BienvenidaComponent [id_company]="id_company" [parent]="this"></app-bienvenida>
          </div>
        </div>


      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_simular">
        <!-- <app-facturas-sii></app-facturas-sii> -->
        <app-simular-facturas></app-simular-facturas>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_ceder">
        <app-simulaciones [subtitle]="'Operaciones listas para Ceder'" [selected]="3" [ocultar_filter]="false"
          [titulo]="'Operaciones'"></app-simulaciones>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_operacion">
            <!--empty msg-->
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" >

              <div class="empty-msg" style="height: 405px;">

                
                <div style="padding-top: 50px;text-align: center;">
                  <i style="font-size: 45px;color:var(--simpli-color6)" class="fa-regular fa-user-helmet-safety"></i>
                </div>
        
                <div style="padding-top: 50px;text-align: center;">
                  <mat-label class="label-bienvenida">
                    <div class="label-empty">
                      
¡Disculpa las molestias! <br>Actualmente, estamos llevando a cabo mejoras en esta sección  <br>para brindarte una experiencia aún más satisfactoria.
                    </div>

                    <div class="label-empty" style="margin-top: 20px;">
                      ¡Gracias por tu comprensión!
                    </div>

                  </mat-label>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                    <div class="btn-simpli-container">
                      <button class="primary-button" (click)="switch_case(1)" mat-flat-button>Simular Operación</button>
                    </div>
                    
                  </div>
                </div>
        
              </div>
        
        
            </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_linea">
        <div class="row w-100">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top"
              style="padding-bottom: 1%;text-align: start;margin: 0 auto;">
              <h1 class="titulo-simpli">Resumen</h1>
            </div>

            <div class="row">
              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
              style="margin:0 auto;margin-bottom: 10px;">
                <small class="montos" style="color:var(--simpli-primary);">$ {{formatMoney(linea_factoring)}} <br> <small
                    class="textoMontos">Linea de factoring</small></small>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
              style="margin:0 auto;margin-bottom: 10px;">
                <small class="montos" style="color:var(--simpli-primary);">$ {{formatMoney(linea_utilizada)}} <br> <small
                    class="textoMontos">Linea utilizada</small></small>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="margin:0 auto;margin-bottom: 10px;">
                <small class="montos" style="color:var(--simpli-primary);">$ {{formatMoney(linea_disponible)}} <br> <small
                    class="textoMontos">Linea disponible</small></small>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="margin:0 auto;margin-bottom: 10px;">
                <small class="montos" style="color:var(--simpli-primary);">$ {{formatMoney(excedentes)}} <br> <small
                    class="textoMontos">Excedentes</small></small>
              </div>
            </div>



          </div>


          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
            <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top"
              style="padding-bottom: 1%;text-align: start;margin:0 auto;">
              <h1 class="titulo-simpli">Resumen del estado de facturas</h1>
            </div>

            <div class="row">
              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="cursor: pointer;margin:0 auto;margin-bottom: 10px;"
                (click)="profundidad('1', 'Facturas en Cobranza')">
                <div style="width: 50%;padding-left: 3%;">
                  <small class="montos" style="color: #1B0943;;">$ {{formatMoney(this.facturas_vigentes.monto)}}</small>
                </div>
                <div style="width: 50%;text-align: end;padding-right: 2%;">
                  <small class="textoCajas"><span style="color:var(--simpli-primary);">{{this.facturas_vigentes.conteo}}</span>
                    facturas <p style="margin: 0;">en cobranza</p></small>
                </div>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="cursor: pointer;margin:0 auto;margin-bottom: 10px;"
                (click)="profundidad('3', 'Facturas con Fecha Pago')">
                <div style="width: 50%;padding-left: 3%;">
                  <small class="montos" style="color:#1B0943;">$ {{formatMoney(this.facturas_procesadas.monto)}}</small>
                </div>
                <div style="width: 50%;text-align: end;padding-right: 2%;">
                  <small class="textoCajas"><span style="color:var(--simpli-primary);">{{this.facturas_procesadas.conteo}}</span>
                    facturas <p style="margin: 0;">con fecha pago</p></small>
                </div>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="cursor: pointer;margin:0 auto;margin-bottom: 10px;"
                (click)="profundidad('2', 'Facturas Pagadas este Mes')">
                <div style="width: 50%;padding-left: 3%;">
                  <small class="montos" style="color:#1B0943;">$ {{formatMoney(this.facturas_pagadas.monto)}}</small>
                </div>
                <div style="width: 50%;text-align: end;padding-right: 2%;">
                  <small class="textoCajas"><span style="color:var(--simpli-primary);">{{this.facturas_pagadas.conteo}}</span>
                    facturas <p style="margin: 0;">pagadas este mes</p></small>
                </div>
              </div>

              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 col-top cajasResumen"
                style="cursor: pointer;margin:0 auto;margin-bottom: 10px;"
                (click)="profundidad('4', 'Facturas con Problemas')">
                <div style="width: 50%;padding-left: 3%;">
                  <small class="montos" style="color:#1B0943;">$ {{formatMoney(this.facturas_problemas.monto)}}</small>
                </div>
                <div style="width: 50%;text-align: end;padding-right: 2%;">
                  <small class="textoCajas"><span style="color:var(--simpli-primary);">{{this.facturas_problemas.conteo}}</span>
                    facturas <p style="margin: 0;">con algunos problemas</p></small>
                </div>
              </div>
            </div>




          </div>




        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" *ngIf="caja_cartola">
        <app-cartola [parent]="this"></app-cartola>
      </div>


    </div>
  </div>
  
</div>

<!--TEMPLATES ONBOARDING-->
<ng-template #doneButton>
  <button class="btn-simpli" (click)="finalizar()" mat-flat-button>Finalizar</button>
</ng-template>

<ng-template #prevButton>
  <button class="arror-onboarding" mat-flat-button (click)="to_top()">
    <i style="font-size: 20px;" class="fa-regular fa-arrow-left"></i>
  </button>
</ng-template>

<ng-template #nextButton>
  <button class="btn-simpli" mat-flat-button  (click)="to_top()">Siguiente</button>
</ng-template>

<ng-template #simular>
  <div class="onboarding">
    Aquí podrás simular tus operaciones de factoring antes de ceder.
  </div>

</ng-template>

<ng-template #confpp>
  <div class="onboarding">
      Entra aquí para solicitar Confirming y/o Pronto Pago.
  </div>
</ng-template>

<ng-template #pp>
  <div class="onboarding">
    Con esta opción podrás dejar el pago a proveedores en nuestras manos.
  </div>
</ng-template>

<ng-template #credenciales>
  <div class="onboarding">
    Ingresa aquí para sincronizar o actualizar tus credenciales del SII.
  </div>
</ng-template>

<ng-template #operaciones>
  <div class="onboarding">
    Aquí podrás revisar el historial de las operaciones que has realizado.
  </div>
</ng-template>

<ng-template #stats>
  <div class="onboarding">
    Con esta opción podrás acceder al detalle del resumen de línea.
  </div>
</ng-template>

<ng-template #cartola>
  <div class="onboarding">
    Con esta opción podrás acceder al detalle de la cartola de movimientos.
  </div>
</ng-template>