
<div class="row row_main">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
                <h1 class="titulo-simpli" style="position: relative;">Mi Cuenta</h1>
                <small class="subtitulo-simpli">Actualiza tu información, sube documentación y gestiona tus credenciales</small>
            </div>



        </div>

        <div class="row">

            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 col-top si-responsive">

                <mat-card>
                    <mat-card-content>

                        <button mat-menu-item style="background: rgba(0,0,0,.04);pointer-events: none;" >
                            <i class="fa-light fa-user icon-simpli"></i>
                              <span style="color: var(--simpli-color3);">Mis cuenta</span>
                        </button>

                        <button mat-menu-item routerLink="/home/cuentas-bancarias">
                            <i class="fa-light fa-credit-card icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Cuentas bancarias</span>
                        </button>

                        <button mat-menu-item  (click)="openDialog()">
                            <i class="fa-light fa-user-lock icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Mis credenciales</span>
                        </button>

                        <button mat-menu-item (click)="changePassword()">
                            <i class="fa-light fa-key icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Cambiar contraseña</span>
                        </button>


                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-lg-5 col-md-4 col-sm-4 col-xs-12 col-top">

                <mat-card>
                    <mat-card-content>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;position: relative;">

                            <h1 class="titulo-simpli">Mis Datos</h1>
                            <small class="subtitulo-simpli">Tus datos personales</small>
                        </div>


                        <form [formGroup]="valForm">


                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Correo Electrónico</mat-label>
                                        <input formControlName="email" matInput type="email" readonly>
                                        <mat-hint>El Correo Electrónico no se puede modificar</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>RUT</mat-label>
                                        <input matInput formControlName="run" readonly>
                                        <mat-hint>El RUT no se puede modificar</mat-hint>

                                        <mat-error
                                            *ngIf="valForm.get('run').hasError('pattern') && (valForm.get('run').dirty || valForm.get('run').touched)">
                                            El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
                                        </mat-error>

                                        <mat-error
                                            *ngIf="valForm.controls['run'].hasError('incorrect') && (valForm.get('run').dirty || valForm.get('run').touched)">
                                            Este RUT ya está en uso
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Nombre</mat-label>
                                        <input  formControlName="first_name" matInput>
                                        <mat-hint>Introduce tu Nombre</mat-hint>
                                        <mat-error
                                            *ngIf="valForm.controls['first_name'].hasError('required') && (valForm.controls['first_name'].dirty || valForm.controls['first_name'].touched)">
                                            Nombre es <strong>requerido</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Apellido</mat-label>
                                        <input formControlName="last_name" matInput>
                                        <mat-hint>Introduce tu Apellido</mat-hint>
                                        <mat-error
                                            *ngIf="valForm.controls['last_name'].hasError('required') && (valForm.controls['last_name'].dirty || valForm.controls['last_name'].touched)">
                                            Apellido es <strong>requerido</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="success-msg animate__animated animate__fadeIn" style="margin-top: 20px;" *ngIf="showsuccess1">
                                <i class="fa fa-check"></i>
                                &nbsp; Datos Actualizados.
                            </div>


                            <div style="width: 100%;margin-top: 40px;margin-bottom: 10px;">
                                <button [disabled]="disable_btn" mat-flat-button color="primary" type="button" (click)="submitForm($event, valForm.value)" class="primary-button">Actualizar</button>
                            </div>
                        </form>

                    </mat-card-content>
                </mat-card>
            </div>


            <div class="col-lg-5 col-md-4 col-sm-4 col-xs-12 col-top">

                <mat-card>
                    <mat-card-content>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px">
                            <h1 class="titulo-simpli">Información Empresa</h1>
                            <small class="subtitulo-simpli">Datos básicos de tu Empresa</small>
                        </div>

                        <form [formGroup]="valFormCompany">

                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Nombre Empresa</mat-label>
                                        <input formControlName="business_name" matInput type="text" readonly>
                                        <mat-hint>El Nombre de la Empresa no se puede modificar</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>RUT Empresa</mat-label>
                                        <input matInput formControlName="run2" readonly>
                                        <mat-hint>El RUT no se puede modificar</mat-hint>

                                        <mat-error
                                            *ngIf="valFormCompany.get('run2').hasError('pattern') && (valFormCompany.get('run2').dirty || valFormCompany.get('run2').touched)">
                                            El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
                                        </mat-error>

                                        <mat-error
                                            *ngIf="valFormCompany.controls['run2'].hasError('incorrect') && (valFormCompany.get('run2').dirty || valFormCompany.get('run2').touched)">
                                            Este RUT ya está en uso
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Giro Empresa</mat-label>
                                        <input formControlName="giro" matInput type="text" readonly>
                                        <mat-hint>El Giro de la Empresa se actualiza automaticamente.</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-top">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Sitio WEB</mat-label>
                                        <input formControlName="web" matInput type="text" (change)="update_web($event)" placeholder="Web">
                                        <mat-hint>Modificar Sitio WEB</mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-12S col-xs-12 col-top">


                                    <mat-form-field appearance="fill">
                                        <mat-label>Correo Electrónico</mat-label>
                                        <input formControlName="email" matInput type="text" (change)="update_mail($event)">
                                        <mat-hint>Modificar Correo Electrónico</mat-hint>

                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="success-msg animate__animated animate__fadeIn" style="margin-top: 20px;" *ngIf="showsuccess">
                                <i class="fa fa-check"></i>
                                &nbsp; Datos Actualizados.
                            </div>


                            <div style="width: 100%;margin-top: 40px;margin-bottom: 10px;">
                                <button [disabled]="disable_btn" mat-flat-button color="primary" type="button" (click)="recargar_sii()" class="primary-button">Actualizar</button>
                            </div>

                        </form>

                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-top no-responsive">

                <mat-card>
                    <mat-card-content>

                        <button mat-menu-item style="background: rgba(0,0,0,.04);pointer-events: none;" >
                            <i class="fa-light fa-user icon-simpli"></i>
                              <span style="color: var(--simpli-color3);">Mis cuenta</span>
                        </button>

                        <button mat-menu-item routerLink="/home/cuentas-bancarias">
                            <i class="fa-light fa-credit-card icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Cuentas bancarias</span>
                        </button>

                        <button mat-menu-item  (click)="openDialog()" class="rhighlight" >
                            <i class="fa-light fa-user-lock icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Mis credenciales</span>
                        </button>

                        <button mat-menu-item (click)="changePassword()" class="rhighlight" >
                            <i class="fa-light fa-key icon-simpli"></i>
                            <span style="color: var(--simpli-color3);">Cambiar contraseña</span>
                        </button>


                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12 col-top">
                <documentos [id_company]="id_company"></documentos>
            </div>


        </div>

    </div>





</div>
