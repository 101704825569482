import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router, NavigationEnd } from '@angular/router';
import moment from 'moment';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { InputDialog } from 'src/app/input-dialog/dialog-input';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;

@Component({
  selector: 'app-excedentes-parrilla',
  templateUrl: './excedentes-parrilla.component.html',
  styleUrls: ['./excedentes-parrilla.component.less']
})
export class ExcedentesParrillaComponent implements OnInit {

  pageSizeOptions: number[] = [10];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageEvent!: PageEvent; 
  length = 0;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  id_company
  page:number=1
  excedentes:any=[]
  fecha_hoy: any; 
  has_excedentes:boolean=false;
  role:any
  deudores:any=[]
  facturas:any=[]
  mes:any=[]
  year:any=[]
  array_doctos:any=[]
  array_deudores:any=[]
  array_meses:any=[]
  array_year:any=[] 
  selected_value1: any
  selected_value2: any
  selected_value3: any
  selected_value4: any
  status_spinner=true

  excedentes_totales: any
  ultima_fecha: any

  activar_filtro=false


  constructor(public dialog:MatDialog,public settings: SettingsService, private router: Router) {

    this.id_company = localStorage.getItem("company_id")

    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.ngOnInit()
      }
    });

   }

  ngOnInit() {
    this.id_company = localStorage.getItem("company_id")
    $("#hidePaginator").hide()

    this.role=localStorage.getItem("role")

    let hoy = new Date();

    let dia = hoy.getDate()
    let mes = hoy.getMonth() + 1

    let year = hoy.getFullYear()

    let hour = hoy.getHours()

    let min = hoy.getMinutes()

    let min_str = min.toString()

    if (min < 10)
      min_str = "0" + min

    this.fecha_hoy = dia + "." + mes + "." + year + " " + hour +":"+min_str  

    this.get_excedentes(1)
   
  }



  today = new Date()
  solicitar_excedentes:boolean=false;
  async get_excedentes(page?:number) {
    try {

      this.status_spinner=true

      const bd: any = await this.settings.get_query(1, 'getexcedente/' + this.id_company +'/?pageNumber='+page);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

              this.excedentes = body_data.excedentes;

              if(this.excedentes.length > 0){
                  this.has_excedentes = true;
                  this.status_spinner=false
                  this.length=body_data.total
                  $("#hidePaginator").show()
                this.deudores=body_data.deudores
                this.facturas=body_data.facturas
                this.mes=body_data.meses
                this.year=body_data.years
                this.excedentes_totales=body_data.excedentes_disponibles
                if(this.excedentes_totales==null){
                    this.excedentes_totales="No tienes excedentes en tus facturas"
                    this.ultima_fecha="No tienes excedentes en tus facturas"
                }
                else{
                  this.ultima_fecha=body_data.ultima_fecha
                }
                
                this.mes.sort(function(a: any,b: any){
                  return a-b
                })
                this.year.sort()
                  
              }else{
                  this.has_excedentes = false;
                  $("#hidePaginator").hide()
                  this.ultima_fecha="No tienes excedentes en tus facturas"
                  this.status_spinner=false
              }

              if(this.ultima_fecha != "No tienes excedentes en tus facturas"){


                let dd = this.today.getDate();
                let mm = String(this.today.getMonth()+1);
                if(mm.length == 1) {
                  mm = '0'+mm
                }

                let yy = this.today.getFullYear();
                let ultima_fecha = dd+'/'+mm+'/'+yy;

                let u_f = new Date(moment(this.ultima_fecha, 'DD/MM/YYYY').format('M-D-YYYY'));
                let date = new Date(moment(ultima_fecha, 'DD/MM/YYYY').format('M-D-YYYY'));



                if(u_f.getTime() == date.getTime() && this.excedentes_totales > 0){
                  console.log("tiene excedentes")
                  this.solicitar_excedentes = true;
                }

              }


              console.log("HERE", this.excedentes_totales)
              console.log(this.ultima_fecha)

              const ultimaFechaParts = this.ultima_fecha.split('/');

              const diaUltimaFecha = parseInt(ultimaFechaParts[0], 10);
              const mesUltimaFecha = parseInt(ultimaFechaParts[1], 10);
              const anioUltimaFecha = parseInt(ultimaFechaParts[2], 10);

              const fechaHoy = new Date();
              const diaHoy = fechaHoy.getDate();
              const mesHoy = fechaHoy.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
              const anioHoy = fechaHoy.getFullYear();

              if (diaUltimaFecha !== diaHoy || mesUltimaFecha !== mesHoy || anioUltimaFecha !== anioHoy) {
                this.excedentes_totales = 0;
                if(this.excedentes_totales == 0){
                  this.solicitar_excedentes = false;
                }
              }




          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  pageCustom=($e: any)=>
  {
    //this.ELEMENT_DATA = []

    if(this.activar_filtro!=true){
      this.page=$e.pageIndex + 1
      this.get_excedentes(this.page)
    }
    else{
      this.page=$e.pageIndex + 1

      this.applyFilter()
    }

    
  }

  filtros=(accion:number, filterValue: any, page?:number) => {
    this.status_spinner=true
    $("#hidePaginator").hide()
    switch(accion) {
      case 1:
        this.array_doctos=[]
        this.array_doctos=filterValue.value
        this.selected_value2=filterValue.value
        console.log(this.array_doctos)
        break;
      case 2:
        this.array_deudores=[]
        this.array_deudores=filterValue.value
        this.selected_value1=filterValue.value
        break;
      case 3:
        console.log(filterValue.value)
        this.array_meses=[]
        this.array_meses.push(filterValue.value)
        this.selected_value3=filterValue.value
        break;
      case 4:
      this.array_year=[]
      this.array_year.push(filterValue.value)
      this.selected_value4=filterValue.value
      break;
      default:
        this.array_doctos=[]
        this.array_deudores=[]
        this.array_meses=[]
        this.array_year=[]
    }

    this.page=1
     
      
    
    
    this.applyFilter()
  }

  reset_filtros=()=>{
    $("#hidePaginator").hide()
    this.status_spinner=true
    this.selected_value1=undefined
    this.selected_value2=undefined
    this.selected_value3=undefined
    this.selected_value4=undefined
    this.array_doctos=[]
    this.array_deudores=[]
    this.array_meses=[]
    this.array_year=[]
    this.page=1

    this.applyFilter()
  }

  async applyFilter() {

    let value: any={"page": this.page}

    if(this.array_doctos.length>0){
      $("#capa").addClass("opacity")
      value["doctos"]=this.array_doctos
      this.activar_filtro=true
    }

    if(this.array_deudores.length>0){
      $("#capa").addClass("opacity")
      value["deudor"]=this.array_deudores
      this.activar_filtro=true
    }

    if(this.array_meses.length>0){
      $("#capa").addClass("opacity")
      value["mes"]=this.array_meses
      this.activar_filtro=true
    }

    if(this.array_year.length>0){
      $("#capa").addClass("opacity")
      value["year"]=this.array_year
      this.activar_filtro=true
    }

    if(this.array_doctos.length==0 && this.array_deudores.length==0 && this.array_meses.length==0 && this.array_year.length==0){
      this.activar_filtro=false
    }

    if(this.activar_filtro==true){

      try {
        const bd: any = await this.settings.get_query(2, 'getexcedente/' + this.id_company +'/?pageNumber='+this.page, value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
              this.status_spinner=false
              this.excedentes=body_data.excedentes
              if(this.excedentes.length>0){
                $("#hidePaginator").show()
                this.has_excedentes=true
                
                if(this.page==1){
                  this.paginator.firstPage();
                  this.paginator.pageIndex = 0;
                }
                
                this.length=body_data.total
                console.log(length)
                
              }else{
                this.has_excedentes=false
                $("#hidePaginator").hide()
              }
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }

    }else{

      this.get_excedentes(1)
      if(this.page==1){
        this.paginator.firstPage();
        this.paginator.pageIndex = 0;
      }

    }


  }


  formatMoney=(amount: any, decimalCount = 2, decimal = ",", thousands = ".")=> {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
    }
  
    open_more_box(c: any, sw = false)
    {
      let prefix = ""
  
      if (sw)
        prefix = "long_"
  
      if ($("#"+prefix+"body_box_"+c).hasClass("body-active"))
      {
        $("#"+prefix+"body_box_"+c).removeClass("body-active")
        $("#"+prefix+"sel_arrow_"+c).removeClass("arrow_active")
      }
      else
      {
        $("#"+prefix+"body_box_"+c).addClass("body-active")
        $("#"+prefix+"sel_arrow_"+c).addClass("arrow_active")
      }
    }

    format_month=(d: any)=>{
      switch (d) {
        case 1:
          return "ENERO"
          break;
       case 2:
          return "FEBRERO"
          break;
      case 3:
          return "MARZO"
          break;
      case 4:
          return "ABRIL"
          break;
      case 5:
          return "MAYO"
          break;
      case 6:
          return "JUNIO"
          break;
      case 7:
          return "JULIO"
          break;
      case 8:
          return "AGOSTO"
          break;
      case 9:
          return "SEPTIEMBRE"
          break;
      case 10:
          return "OCTUBRE"
          break;
      case 11:
          return "NOVIEMBRE"
          break;
      case 12:
          return "DICIEMBRE"
          break;
        default:
          break;
      }
    }
  
  syncCredentials(){
    const dialogRef = this.dialog.open(CredencialesSII,{ 
      width:'600px',
      autoFocus: false 
    }
    );
    dialogRef.componentInstance.id_company = this.id_company;
  }


    open_credenciales(){
      this.router.navigate(['/', 'home'])
      .then(nav => {
  
        setTimeout(() => {
          $("#caja_excel").trigger('click')
        });
  
      }, err => {
        console.log(err) // when there's an error
      });
    }

  
    loading_excentes:boolean=false;
    async sol_excedentes() {
      try {

        this.loading_excentes = true;

        let value = {'excedentes': this.excedentes_totales}

        const bd: any = await this.settings.get_query(2, 'cliente/solicitud-excedentes/' + this.id_company + '/', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
            this.loading_excentes = false;

            this.open_input_dialog("Solicitud de Excedentes", "Solicitud Exitosa", 
            "¡Todo listo! Tú solicitud fue enviada a tu ejecutivo.",
            "Revisa el estado de tu solicitud en la sección de operaciones.","assignment_turned_in","#3BA46F")
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
        this.loading_excentes = false;
      }
    }


    //DIALOG INPUT
    open_input_dialog(title: any,subtitle: any,mensajeprincipal: any,mensajenegrita: any,icon: any,coloricon: any){

      const dialogRef = this.dialog.open(InputDialog,{
        width:'700px'
      });
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.subtitle = subtitle;
      dialogRef.componentInstance.mensajeprincipal = mensajeprincipal;
      dialogRef.componentInstance.mensajenegrita = mensajenegrita;
      dialogRef.componentInstance.icon = icon;
      dialogRef.componentInstance.coloricon = coloricon;
    
    
      dialogRef.afterClosed().subscribe((result:any) => {

      });
    
    }

}
