<div mat-dialog-title>

    <h1 class="titulo-simpli">Empresas activas</h1>
    <small class="subtitulo-simpli">Selecciona una empresa para visualizar su información</small>


</div>

<div mat-dialog-content style="min-height: 300px;">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;margin-top: 20px;">
  

    
    <ng-container *ngIf="load">
      <div class="velo-inactivo animate__animated animate__fadeIn center__items position__absolute"
      style="background: #ffffff70; height: 100%"> 
      </div>
    </ng-container>
    
    <form  *ngIf="rol =='clie'">
      <mat-form-field (click)="reset()"  appearance="fill">
        <mat-label style="color:var(--color-letra);">Empresas</mat-label>
        <mat-select  [(ngModel)]="selected2" name="something" (selectionChange)="empresa_seleccionada($event)">
          <mat-option  *ngFor="let state of states" [value]="state.id" style="color:var(--color-letra) !important;">
            <span style="color: var(--color-letra) !important;">{{state.name}}</span>
          </mat-option>
        </mat-select>
  
      </mat-form-field>
    </form>
  
    <form *ngIf="rol == 'admi'">
      <mat-form-field appearance="fill">
          <mat-label>Empresas</mat-label>
          <input type="text" aria-label="Number" matInput [formControl]="myControl"
              [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete"
              (optionSelected)="selected($event)" >
              <mat-option *ngFor="let option of filteredOptions | async" data-id="{{option.id}}" 
                  [value]="option.name">
                  {{option.name}}
              </mat-option>
          </mat-autocomplete>
          <mat-spinner *ngIf="load" style="margin-right: 10px;"  diameter="20" matSuffix></mat-spinner>
      </mat-form-field>
    </form>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top animate__animated animate__fadeIn" *ngIf="showmsg">
      <div class="success-msg " >
        <i class="fa fa-check"></i>
        &nbsp; ¡Cambio exitoso! Haz clic en cerrar y continúa.
      </div>
    </div>


  
  </div>

</div>

<div mat-dialog-actions>

  <button mat-dialog-close mat-flat-button class="secondary-button">Cerrar</button>

</div>




