<div mat-dialog-title>

      <h1 class="titulo-simpli">Adjunta tu Certificado Digital Electrónico</h1>
      <small class="subtitulo-simpli">También debes ingresar el RUT del titular y la clave asociada.</small>


</div>

<div mat-dialog-content>
    <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <mat-card class="animate__animated animate__fadeIn" style="border: none;box-shadow: none !important;">

            <mat-card-content>

                <form  [formGroup]="valForm" >

                    <div class="row">


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">

                            <mat-form-field appearance="fill" color="primary">
                                <mat-label>Facturador Electrónico</mat-label>
                                <input  matInput formControlName="facturador" [matAutocomplete]="auto" [formControl]="stateCtrl" (change)="validate($event)">
                                <mat-autocomplete #auto="matAutocomplete" class="add_factoring" (optionSelected)="set_facturador($event)"> 
                                    <mat-option *ngFor="let state of filteredStates | async" [value]="state.name" >
                                      <img *ngIf="state.flag != null" class="example-option-img" aria-hidden [src]="state.flag" height="25">
                                      <mat-label *ngIf="state.flag == null" style="font-size: 16px;font-weight: bold;color:#1b0943;">Utilizo otro Facturador</mat-label>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-spinner diameter="20" color="primary" matSuffix *ngIf="saving_facturador"></mat-spinner>
                                <mat-icon matSuffix *ngIf="success_facturador" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                                <mat-hint>Selecciona tu facturador electrónico</mat-hint>
                            </mat-form-field>


                            <mat-form-field color="primary" appearance="fill" *ngIf="mostrar_form == true">
                                <mat-label>Nombre Facturador</mat-label>
                                <input matInput formControlName="otro_facturador" (change)="escribio_otro()">
                                <mat-icon matSuffix *ngIf="success_facturador_otro" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                                <mat-hint>Indique el nombre de su Facturador Electrónico</mat-hint>
                            </mat-form-field>

                            <mat-form-field color="primary" appearance="fill" *ngIf="mostrar_form2">
                                <mat-label>Usuario Nubox</mat-label>
                                <input required matInput formControlName="rut_nubox" >
                                <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_facturador_nubox" ></mat-spinner>
                                <mat-hint>Indique el usuario de su facturador Nubox</mat-hint>
                                <mat-error *ngIf="valForm.controls['rut_nubox'].hasError('required')">
                                    El usuario es requerido.
                                </mat-error>
  
                            </mat-form-field>

                            <mat-form-field color="primary" appearance="fill" *ngIf="mostrar_form2">
                                <mat-label>Contraseña Nubox</mat-label>
                                <input required matInput formControlName="clave_nubox"  type="password"   >
                                <mat-spinner color="primary" diameter="20" matSuffix *ngIf="saving_facturador_nubox"></mat-spinner>
                                <mat-icon matSuffix *ngIf="success_facturador_nubox" class="animate__animated animate__fadeIn" style="color:#3BA46F;">check_circle</mat-icon>
                                <mat-hint>Indique la contraseña de su facturador Nubox</mat-hint>
                                <mat-error *ngIf="valForm.controls['clave_nubox'].hasError('required')">
                                    La contraseña es requerida.
                                </mat-error>
                            </mat-form-field>



                            
        
                        </div>


                    </div>



                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  
          <div [ngClass]="{'disable-btn':disable_btn == true, 'btn-simpli-container':true}">

            <button *ngIf="mostrar_put_nubox==true"  [disabled]="disable_btn"  mat-flat-button class="primary-button" type="button" style="margin-top: 10px;" (click)="submitForm($event, valForm.value)">
                <mat-label *ngIf="!disable_btn">Guardar</mat-label>
                <span *ngIf="disable_btn"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
            </button>
            <button *ngIf="mostrar_put_nubox==false"  [disabled]="disable_btn"  mat-flat-button class="primary-button" type="button" style="margin-top: 10px;" (click)="put_facturador()">
                <mat-label *ngIf="!disable_btn">Guardar</mat-label>
                <span *ngIf="disable_btn"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
            </button>

          </div>
          
        </div>
    </div>

</div>
