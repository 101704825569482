<div mat-dialog-title>

    <h1 class="titulo-simpli">{{title}}</h1>
    <small class="subtitulo-simpli">{{subtitle}}</small>

</div>


<div mat-dialog-content class="animate__animated animate__fadeIn">

  <div class="col-lg-12 col-top" *ngIf="hay_facturas_con_error">
    <div class="warning-msg" style="background-color: transparent !important;">
      <i  class="fa-light fa-circle-exclamation"  style="color:#ff933b;"></i>
      &nbsp; {{ mensaje_error }}
      </div>
  </div>

  <div class="col-lg-12 col-top" *ngIf="todas_las_facturas_ok">
    <div class="success-msg" style="background-color: transparent !important;">
      <i class="fa-light fa-check-circle" style="color:#36a03f;"></i>
      &nbsp; ¡Proceso exitoso!
      </div>
  </div>

  <div class="col-lg-12 col-top" *ngIf="show_descargar_plantilla">
    <button (click)="descargar_file()" mat-flat-button class="primary-button " style="width: 100%; margin-left: 0px !important;">Descarga plantilla</button>
  </div>


  <div class="col-lg-12 col-top" >
    <ng-container *ngIf="!process">

      <div class="row btn-carga-manual" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
        <input id="file_but" (change)="onChange($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" style="display:none;"/>
        <div class="col-lg-12 justify-content-center">
          <h3>Arrastra plantilla aquí o</h3>
        </div>
        <div class="col-lg-12 justify-content-center">
          <button (click)="subir()" mat-flat-button class="primary-button">Sube plantilla</button>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class="row" *ngIf="process">
        <div class="col-lg-12 justify-content-center">
          <mat-spinner class="spinner-simpli" style="margin-top: 200px" diameter="40"></mat-spinner>
        </div>
      </div>
    </ng-container>
  </div>


</div>

<div mat-dialog-actions>
    <button mat-flat-button class="secondary-button" mat-dialog-close>Cerrar</button>
    <button mat-flat-button class="primary-button" (click)="financiar()" [disabled]="disabled_button" color="primary">{{label_boton_financiar}}</button>
</div>

