import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router, RouterEvent } from '@angular/router';
import { SettingsService } from '../../settings/settings.service';
import { CredencialesSII } from '../../credenciales-sii/credenciales-sii';
import { Title, Meta } from '@angular/platform-browser';  
import { BienvenidaComponent } from '../cliente-new-version/bienvenida.component';
import { MatDialog } from '@angular/material/dialog';
import { UrlService } from 'src/app/services/url.service';

declare var $: any;

@Component({
  selector: 'app-clientenuevo',
  templateUrl: './clientenuevo.component.html',
  styleUrls: ['./clientenuevo.component.less']
})
export class ClienteNuevoComponent implements OnInit, OnDestroy {

  id_company: any;
  mensajes: any[] = [
    "Atrévete a hacer posible, lo que los demás creen imposible.",
    "Así como un copo se transforma en una bola de nieve que cambia todo a su paso, una idea se transforma en un proyecto que cambia el mundo.",
    "Ninguna noche venció al amanecer. Que ninguna dificultad te detenga en tu camino al éxito.",
    "Hay buenos días y malos días. Lo que diferencia a los emprendedores es su habilidad para transformar lo malo en bueno.",
    "La gente positiva es la que se cae, se levanta, se sacude, se cura los raspones, sonríe a la vida y dice 'Allá voy de nuevo'"
  ]
  constructor(private router: Router, public settings: SettingsService, public dialog: MatDialog,     
    private titleService: Title,  
    private metaTagService: Meta, public route:Router,private urlService: UrlService  ) { 

      route.routeReuseStrategy.shouldReuseRoute = () => false;

      // const urlOriginal = "home/contacto"
      // this.urlService.setOriginalUrl(urlOriginal);

    }

  ngOnInit() {

    this.titleService.setTitle("Clientes Simpli"); 
    this.metaTagService.addTags([  
      { name: 'description', content: 'Recibe liquidez para tu empresa, paga tus compromisos y accede a un ecosistema de soluciones financieras. Solo debes registrarte e ingresar a nuestra plataforma 100% digital.' },
      { name: 'keywords', content: 'factoring, financiamiento, simulaciones, operaciones, liquidez' },  
      { name: 'author', content: 'Simpli S.A.' },      ]);  

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });

    this.load_all()

  }

  item = "";
  load_all() {
    this.id_company = localStorage.getItem('company_id');

    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.operacionesPorCeder()

    this.get_credenciales()
    this.load_metricas()
    this.guardar_visita()
    this.item = this.mensajes[Math.floor(Math.random() * this.mensajes.length)];


    //Cargar chat
    if(this.route.url == "/home/home/home"){
      setTimeout(() => {
        $("#chatEjecutivo").trigger('click');
      }, 2000);
    }

    if(this.route.url == "/home/contacto"){
      setTimeout(() => {
        $("#chatEjecutivo").trigger('click');
      }, 2000);
    }


  }

  myObserver: any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngAfterViewChecked() {

    setTimeout(() => {
      $("#gbChatbot").css('display', 'block')
    });

  }

  case_excel: boolean = false;
  case_nomina: boolean = false;
  case_operacion: boolean = false;
  case_calendar: boolean = false;
  case_linea: boolean = false;

  //new variables
  caja_mensaje_inspirador: boolean = true;
  caja_simular: boolean = false;
  caja_operacion: boolean = false;
  caja_credenciales: boolean = false;
  caja_ceder: boolean = false;
  caja_linea: boolean = false;
  caja_cartola: boolean = false;
  
  is_responsive:boolean=false;
  switch_case(number: any) {

    switch (number) {
      case 1:
        if(this.is_responsive == true){

          this.router.navigate(['home/simular-facturas-r/',this.id_company])

        }else{
            //cargar
            this.caja_operacion = false;
            this.caja_credenciales = false;
            this.caja_ceder = false;
            this.caja_mensaje_inspirador = false;
            this.caja_linea = false;
            this.caja_cartola = false;
            $("#caja_nomina").removeClass("nominaboxhover")
            $("#caja_operacion").removeClass("operacionboxhover")
            $("#caja_calendar").removeClass("calendarboxhover")
            $("#caja_linea").removeClass("lineaboxhover")
            $("#caja_cartola").removeClass("cartolaboxhover")

            if (this.caja_simular == true) {
              $("#caja_excel").addClass("excelboxhover");
            } else if (this.caja_simular == false) {
              this.caja_simular = true;
              $("#caja_excel").addClass("excelboxhover");
            }
        }
        break;
      case 2:
        //operacion
        this.caja_simular = false;
        this.caja_credenciales = false;
        this.caja_ceder = false;
        this.caja_mensaje_inspirador = false;
        this.caja_linea = false;
        this.caja_cartola = false;
        $("#caja_excel").removeClass("excelboxhover")
        $("#caja_operacion").removeClass("operacionboxhover")
        $("#caja_calendar").removeClass("calendarboxhover")
        $("#caja_linea").removeClass("lineaboxhover")
        $("#caja_cartola").removeClass("cartolaboxhover")

        if (this.caja_operacion == true) {
          $("#caja_nomina").addClass("nominaboxhover");
        } else if (this.caja_operacion == false) {
          this.caja_operacion = true;
          $("#caja_nomina").addClass("nominaboxhover");
        }
        break;
      case 3:
        //credenciales
        this.caja_ceder = false
        this.caja_simular = false;
        this.caja_operacion = false;
        this.caja_mensaje_inspirador = false;
        this.caja_linea = false;
        this.caja_cartola = false;
        $("#caja_excel").removeClass("excelboxhover")
        $("#caja_nomina").removeClass("nominaboxhover")
        $("#caja_calendar").removeClass("calendarboxhover")
        $("#caja_linea").removeClass("lineaboxhover")
        $("#caja_cartola").removeClass("cartolaboxhover")

        if (this.caja_credenciales == true) {
          $("#caja_operacion").addClass("operacionboxhover");
        } else if (this.caja_credenciales == false) {
          this.caja_credenciales = true;
          $("#caja_operacion").addClass("operacionboxhover");
        }
        break;
      case 4:
        this.caja_operacion = false
        this.caja_credenciales = false;
        this.caja_simular = false;
        this.caja_mensaje_inspirador = false;
        this.caja_linea = false;
        this.caja_cartola = false;
        $("#caja_excel").removeClass("excelboxhover")
        $("#caja_nomina").removeClass("nominaboxhover")
        $("#caja_operacion").removeClass("operacionboxhover")
        $("#caja_linea").removeClass("lineaboxhover")
        $("#caja_cartola").removeClass("cartolaboxhover")

        if (this.caja_ceder == true) {
          $("#caja_calendar").addClass("calendarboxhover");
        } else if (this.caja_ceder == false) {
          this.caja_ceder = true;
          $("#caja_calendar").addClass("calendarboxhover");
        }

        break;
      case 5:
        this.caja_operacion = false
        this.caja_credenciales = false;
        this.caja_simular = false;
        this.caja_mensaje_inspirador = false;
        this.caja_ceder = false;
        this.caja_cartola = false;

        $("#caja_excel").removeClass("excelboxhover")
        $("#caja_nomina").removeClass("nominaboxhover")
        $("#caja_operacion").removeClass("operacionboxhover")
        $("#caja_calendar").removeClass("calendarboxhover")
        $("#caja_cartola").removeClass("cartolaboxhover")

        if (this.caja_linea == true) {
          $("#caja_linea").addClass("lineaboxhover");
        } else if (this.caja_linea == false) {
          this.caja_linea = true;
          $("#caja_linea").addClass("lineaboxhover");
        }

        break;
      case 6:
        console.log("navigate")
        this.navigateToLogin()
        break;

      case 7: 
      if(this.is_responsive == true){
        this.router.navigate(['home/cartola-r/',this.id_company])
      }else{
        this.caja_operacion = false
        this.caja_credenciales = false;
        this.caja_simular = false;
        this.caja_mensaje_inspirador = false;
        this.caja_ceder = false;
        this.caja_linea = false;

        $("#caja_excel").removeClass("excelboxhover")
        $("#caja_nomina").removeClass("nominaboxhover")
        $("#caja_operacion").removeClass("operacionboxhover")
        $("#caja_calendar").removeClass("calendarboxhover")
        $("#caja_linea").removeClass("lineaboxhover")

        if (this.caja_cartola == true) {
          $("#caja_cartola").addClass("cartolaboxhover");
        } else if (this.caja_cartola == false) {
          this.caja_cartola = true;
          $("#caja_cartola").addClass("cartolaboxhover");
        }
      }


        break;

    }

  }

  navigateToLogin() {
    this.router.navigateByUrl('/home/pago-proveedores');
  }

  openDialog() {
    var dialogRef:any;
    if(this.is_responsive == true){
       dialogRef = this.dialog.open(CredencialesSII, {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        autoFocus: false,
        disableClose: false,
      });
    }else{
       dialogRef = this.dialog.open(CredencialesSII, {
        width: '600px',
        autoFocus: false
      });
    }

    dialogRef.componentInstance.id_company = this.id_company
    dialogRef.componentInstance.token = localStorage.getItem('token');

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
      this.get_credenciales()
    });
  }

  //cargar metricas
  excedentes = 0;
  linea_disponible = 0;
  linea_factoring = 0;
  linea_utilizada = 0;
  facturas_pagadas = { 'conteo': 0, 'monto': 0 };
  facturas_problemas = { 'conteo': 0, 'monto': 0 };
  facturas_procesadas = { 'conteo': 0, 'monto': 0 };
  facturas_vigentes = { 'conteo': 0, 'monto': 0 };
  simulaciones_ceder:any = 0;

  async load_metricas(){
    try {


      const bd: any = await this.settings.get_query(1, 'lineacliente/' + this.id_company + '/', );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          this.excedentes = body_data.excedentes;
          this.linea_disponible = body_data.linea_disponible;
          this.linea_factoring = body_data.linea_factoring;
          this.linea_utilizada = body_data.linea_utilizada;

          this.facturas_pagadas = body_data.facturas_pagadas;
          this.facturas_problemas = body_data.facturas_problemas;
          this.facturas_procesadas = body_data.facturas_procesadas;
          this.facturas_vigentes = body_data.facturas_vigentes;


          this.simulaciones_ceder = body_data.simulaciones_ceder;


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  async load_ops(){
    try {


      const bd: any = await this.settings.get_query(1, 'simsporceder/' + this.id_company + '/', );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.simulaciones_ceder = body_data.simulaciones_ceder;


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  async operacionesPorCeder() {

    try {
      const bd: any = await this.settings.get_query(1, `cliente/simulacion-por-ceder/${this.id_company}/`);
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:
 
          this.simulaciones_ceder = data;
          if(data == null){
            this.simulaciones_ceder = 0
          }
          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.settings.manage_errors(bd.status, "operacionesPorCeder", bd, false);
    }
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }

  vencio_certificado: boolean = false;
  rut: any;
  clave_pfx: any;
  clave_sii: any;
  file: any;
  falta_cargar_credenciales: boolean = false;
  mensaje2 = "Hey! Aún no estás enrolado... ¿Sabías que al enrolarte con tus credenciales del SII, puedes visualizar todas las facturas disponibles para simular y ceder en solo dos pasos?"
  async get_credenciales(){
    try {


      const bd: any = await this.settings.get_query(1, 'cliente/obtener-credenciales-sii/' + this.id_company + '/', );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.rut = body_data.rut_autorizado
        this.clave_pfx = body_data.clave_pfx
        this.clave_sii = body_data.code
        this.vencio_certificado = body_data.date_boolean;
        this.file = body_data.file;


        if (this.clave_sii != null && this.clave_sii != "" && this.clave_sii != undefined) {
          this.falta_cargar_credenciales = false;
          localStorage.setItem('keys', 'true');

          this.mensajes = [
            "Atrévete a hacer posible, lo que los demás creen imposible.",
            "Así como un copo se transforma en una bola de nieve que cambia todo a su paso, una idea se transforma en un proyecto que cambia el mundo.",
            "Ninguna noche venció al amanecer. Que ninguna dificultad te detenga en tu camino al éxito.",
            "Hay buenos días y malos días. Lo que diferencia a los emprendedores es su habilidad para transformar lo malo en bueno.",
            "La gente positiva es la que se cae, se levanta, se sacude, se cura los raspones, sonríe a la vida y dice 'Allá voy de nuevo'"
          ]
          this.item = this.mensajes[Math.floor(Math.random() * this.mensajes.length)];
        } else {
          this.falta_cargar_credenciales = true;
          localStorage.setItem('keys', 'false')
        }


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  enrolar() {
    $("#caja_operacion").trigger('click')
  }



  async guardar_visita(){
    try {

      let value = { 'pantalla': 'factoring-home' }
      const bd: any = await this.settings.get_query(2,'visitas-clientes-pantalla/', value );
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.rut = body_data.rut_autorizado
        this.clave_pfx = body_data.clave_pfx
        this.clave_sii = body_data.code
        this.vencio_certificado = body_data.date_boolean;
        this.file = body_data.file;


        if (this.clave_sii != null && this.clave_sii != "" && this.clave_sii != undefined) {
          this.falta_cargar_credenciales = false;
          localStorage.setItem('keys', 'true');

          this.mensajes = [
            "Atrévete a hacer posible, lo que los demás creen imposible.",
            "Así como un copo se transforma en una bola de nieve que cambia todo a su paso, una idea se transforma en un proyecto que cambia el mundo.",
            "Ninguna noche venció al amanecer. Que ninguna dificultad te detenga en tu camino al éxito.",
            "Hay buenos días y malos días. Lo que diferencia a los emprendedores es su habilidad para transformar lo malo en bueno.",
            "La gente positiva es la que se cae, se levanta, se sacude, se cura los raspones, sonríe a la vida y dice 'Allá voy de nuevo'"
          ]
          this.item = this.mensajes[Math.floor(Math.random() * this.mensajes.length)];
        } else {
          this.falta_cargar_credenciales = true;
          localStorage.setItem('keys', 'false')
        }


        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  action() {
    // console.log("action")
    if ($(window).width() < 900) {
      // console.log("el scroll es menor a 900")
    } else {
      setTimeout(() => {    //<<<---    using ()=> syntax
        //$(".joyride-step__container")[0].scrollIntoView();
        $("html")[0].scrollIntoView({ block: "end", behavior: "smooth" });
      }, 200);
    }

  }

  to_top(){
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  onDone() {
    this.bienvenida.step_to_show = 5;
  }

  @ViewChild('BienvenidaComponent') bienvenida!: BienvenidaComponent;
  finalizar(){
    this.bienvenida.step_to_show = 5;
  }



}