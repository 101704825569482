
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { PasswordComponent } from './password/password.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CredencialesSII } from 'src/app/credenciales-sii/credenciales-sii';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less'],

})
export class InfoComponent implements OnInit, OnDestroy {
  

  id_company:any;

  valForm:FormGroup;
  valFormCompany:FormGroup;




  constructor(private activated_router: ActivatedRoute, fb: FormBuilder,public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService) {


    let rutValid = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9K]$')]));
    let rutValid2= new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{7,10}[-][0-9K]$')]));

    this.valForm = fb.group({
      'email': ['', Validators.compose([Validators.required])],
      'first_name': ['', Validators.compose([Validators.required])],
      'last_name': ['', Validators.compose([Validators.required])],
      'run': rutValid
    });

    this.valFormCompany = fb.group({
      'run2': rutValid2,
      'business_name':['', Validators.compose([Validators.required])],
      'giro':'',
      'email':'',
      'web':''
    });

    this.activated_router.params.subscribe((params:any) => {
      this.id_company = params['id']
     
    })




  }



   load_all()
   {
    this.id_company = localStorage.getItem("company_id")

    this.initial_load();
    this.initial_load_company()
    this.load_companies()



   }


   myObserver:any;

   ngOnDestroy() {
     this.myObserver.unsubscribe();
   }
 
   ngOnInit() {
    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all()
        
      } ;
     });

     this.load_all()

   }

   run:any;
   async change_run() {
    try {

      this.run = this.valForm.controls['run'].value;

      let st = this.run.replace("-", "");
      let arr = st.split("");
      let st_out = "";
  
  
      for (let i=0; i < arr.length; i++)
      {
        st_out = st_out + arr[i];
  
        if (i == arr.length - 2)
        {
          st_out = st_out + "-"
        }
      }
  
      this.run=st_out;
  
      let run = this.run;

      let value: any = {"run": run};

      const bd: any = await this.settings.get_query(2, 'verify-run-ind/',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:




          break;
        default:

          break;
      }
    } catch (bd: any) {
        this.valForm.controls['run'].setErrors({'incorrect': true});
    }
  }
 


   showsuccess1:boolean=false
   async submitForm($ev:any, value: any) {

    $ev.preventDefault();
 

    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      try {
        const bd: any = await this.settings.get_query(3, 'current-user-update/',value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
                this.initial_load_company();
                this.load_companies()

                this.showsuccess1 = true;
                this.disable_btn = false;

                setTimeout(()=>{          
                this.showsuccess1 = false;
              }, 3000);
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }

  }

   showsuccess:boolean=false;
   disable_btn:boolean=false;
   async submitFormCompany($ev:any, value: any) {

    $ev.preventDefault();

    for (let c in this.valFormCompany.controls) {
        this.valFormCompany.controls[c].markAsTouched();
    }

    if (this.valFormCompany.valid) {
      try {

        this.disable_btn = true

        const bd: any = await this.settings.get_query(3, 'company-update/' + this.id_company + '/', value);
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
          this.initial_load_company();
          this.load_companies()

          this.showsuccess = true;
          this.disable_btn = false;

          setTimeout(()=>{          
           this.showsuccess = false;
         }, 3000);
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }

  }
 
   avatar:any;
   tiene_avatar:boolean=false;
   async initial_load() {
    try {
      const bd: any = await this.settings.get_query(1,  'current-user/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        console.log("body aca", body_data)

        this.valForm.controls['email'].setValue(body_data.user.email);
        this.valForm.controls['first_name'].setValue(body_data.user.first_name);
        this.valForm.controls['last_name'].setValue(body_data.user.last_name);
        this.valForm.controls['run'].setValue(body_data.user.run);

        if(body_data.user.avatar == "" || body_data.user.avatar == null){
          this.tiene_avatar = false;
          this.avatar =  body_data.user.first_name.charAt(0) + body_data.user.last_name.charAt(0);
        }

        if (body_data.user.avatar != null && body_data.user.avatar != "") {
         this.avatar = SettingsService.API_ENDPOINT + body_data.user.avatar
       }else{
         this.avatar = "../../../assets/img/avatar-default.png"
       }


          break;
        default:

          break;
      }
    } catch (bd: any) {
      console.log("error", bd)
    }
  }

   async initial_load_company() {
    try {
      const bd: any = await this.settings.get_query(1, 'company/'+this.id_company+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.valFormCompany.controls['run2'].setValue(body_data.company.rut);
        this.valFormCompany.controls['business_name'].setValue(body_data.company.business_name);
        if(body_data.company.giros.length >0){
         this.valFormCompany.controls['giro'].setValue(body_data.company.giros[0].name);
        }

        this.valFormCompany.controls['email'].setValue(body_data.company.email);
        this.valFormCompany.controls['web'].setValue(body_data.company.web);


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }
 
  
  remove_char(x:string, char:any)
  {
    let arr = x.split(char);
  
    let y=""
  
    for (let j = 0; j < arr.length; j++)
    {
      y = y + arr[j];
    }
  
    return y
  }
  
  transform_rut($e:any){
  
    let st = $e.target.value
    st = this.remove_char(st, "-")
    let arr = st.split("");
    let st_out = "";
  
    for (let i=0; i < arr.length; i++)
    {
      st_out = st_out + arr[i];
  
      if (i == arr.length - 2)
      {
        st_out = st_out + "-"
      }
    }
  
    let rut = st_out;
  
  }
  
  async load_companies() {
    try {
      this.id_company = localStorage.getItem('company_id')
      const bd: any = await this.settings.get_query(1, 'clientrelatedcompany/' + this.id_company + '/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:




          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  async update_mail($e:any) {
    try {
      let value = {'email':$e.target.value} 

      const bd: any = await this.settings.get_query(3, 'prospecto/' + this.id_company +'/',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:




          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  async update_web($e:any) {
    try {
      let value = {'pagina_web':$e.target.value} 

      const bd: any = await this.settings.get_query(3, 'prospecto/' + this.id_company +'/',value);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:




          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }


  openDialog() {
    console.log("open")
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false,
    });
    dialogRef.componentInstance.id_company = this.id_company

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }


  changePassword() {

    const dialogRef = this.dialog.open(PasswordComponent,{
      width: '700px',
      autoFocus: false,
    });
    dialogRef.componentInstance.open_as_dialog = true;
 
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }


  codigos:any[] = [];
  giro:any[] = [];
  async recargar_sii() {
    try {

      this.disable_btn = true;

      let rut = this.valFormCompany.controls["run2"].value;

      const bd: any = await this.settings.get_query(1, "siiarrayan/?rut=" + rut + "");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        if (body_data.error == "Rut invalido") {

        } else {


          this.codigos = [];
          this.giro = [];

          for (let i = 0; i < body_data.actividades.length; i++) {
            let actividades = body_data.actividades[i].codigo;
            let giross = body_data.actividades[i].giro;

            this.giro.push(giross);
            this.codigos.push(actividades);

            if (i == 0)
              this.valFormCompany.controls["giro"].setValue(
                body_data.actividades[i].giro
              );

        
          }

          this.disable_btn = false;

        }

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }






}

