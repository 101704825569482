<nav class="animate__animated" *ngIf="menu_cliente == true" style="margin-top: 30px;">
    <ul style="line-height:280%">

        <!--Inicio-->
        <li>
            <a  routerLink="/home">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-house material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Inicio" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Liquidaciones-->
        <li>
            <a  routerLink="/home/liquidaciones" routerLinkActive="router-link-active">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-envelope-open-dollar material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Liquidaciones" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Excedentes-->
        <li>
            <a  routerLink="/home/excedentes" routerLinkActive="router-link-active">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-badge-percent material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Excedentes" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Facturas Nubox-->
        <li>
            <a  routerLink="/home/facturas-arrayan" routerLinkActive="router-link-active">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fal fa-file-invoice-dollar material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Facturas Simpli" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>


        <!--Pago Proveedores-->
        <li class="sub-menu special-hover">
            <a  routerLinkActive="router-link-active" 
            
            [ngClass]="{'router-link-active': 
            pagoproveedores.isActive || 
            gestionproveedores.isActive || 
            facturaspagadas.isActive ||
            pagoremuneraciones.isActive }">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-hand-holding-dollar material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated" style="position: relative;">
                        <input value="Payment" readonly class="sin-input">
                        <!--(mouseenter)="mouseenter(true)"-->
                        <div class="fa fa-caret-down right flecha-desplegable"></div>
                    </div>
                </div>
            </a>


            <ul id="menupagoproveedores" style="line-height:130%;font-size: 14px;">

                <li>

                    <a  #pagoproveedores="routerLinkActive"  routerLink="/home/pago-proveedores" routerLinkActive="router-link-active">
                        Pago a Proveedores
                    </a>
                </li>

                <li>

                    <a  #pagoremuneraciones="routerLinkActive"  routerLink="/home/pago-remuneraciones" routerLinkActive="router-link-active">
                        Pago de Remuneraciones
                    </a>
                </li>

                <li>
                    <a  #gestionproveedores="routerLinkActive" routerLink="/home/pago-proveedores-fechas" routerLinkActive="router-link-active">
                        Gestión de Proveedores
                    </a>
                </li>

                <li>
                    <a  #facturaspagadas="routerLinkActive" routerLink="/home/pp-facturas-pagadas" routerLinkActive="router-link-active">
                        Facturas pagadas
                    </a>
                </li>





            </ul>
        </li>

        <!--Simulaciones-->
        <li>
            <a *ngIf="!is_responsive" routerLink="/home/simulaciones" routerLinkActive="router-link-active">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-memo-circle-check material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Simulaciones" readonly class="sin-input">
                    </div>
                </div>
            </a>

            <a *ngIf="is_responsive" routerLink="/home/simulaciones-r/{{id_company}}" routerLinkActive="router-link-active">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-memo-circle-check material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Simulaciones" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Cartera-->
        <li>
            <a  routerLink="/home/detalle-cartera" routerLinkActive="router-link-active">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-wallet material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Cartera" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Operaciones-->
        <li>
            <a  id="menu-operaciones" routerLink="/home/operaciones"  
            [ngClass]="{'router-link-active': 
            this.router.url == '/home/operaciones-remuneraciones' || 
            this.router.url == '/home/operaciones-proveedores' ||
            this.router.url == '/home/operaciones'  }">


                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-list-check material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Operaciones" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Dashboard-->
        <li>
            <a  routerLink="/home/dashboard"  [ngClass]="{'router-link-active': 
            this.router.url == '/home/dashboard-compras' || this.router.url == '/home/dashboard' }">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-chart-mixed material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Dashboard" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>

        <!--Cartola-->
        <li>
            <a *ngIf="!is_responsive" routerLink="/home/cartola"  [ngClass]="{'router-link-active': 
            this.router.url == '/home/cartola'}">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-chart-line material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Cartola" readonly class="sin-input">
                    </div>
                </div>
            </a>

            <a *ngIf="is_responsive" routerLink="/home/cartola-r"  [ngClass]="{'router-link-active': 
            this.router.url == '/home/cartola'}">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-chart-line material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Cartola" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>


        <!--mis movimientos-->
        <li>
            <a  routerLink="/home/resumen-linea" (click)="go_to_resumen()">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-chart-column material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Resumen linea" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>




    </ul>
</nav>

<nav class="animated bounceInDown" *ngIf="menu_confirming_pronto_pago == true">
    <ul style="line-height:280%">

        <!--Inicio-->
        <li>
            <a  routerLink="/home/confirming">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-house material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Inicio" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>


        <!--Pago Proveedores-->
        <li>
            <a  routerLink="/home/dashboard" routerLinkActive="router-link-active">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-hand-holding-dollar material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Payment" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>


        <!--Liquidaciones-->
        <li>
            <a  routerLink="/home/liquidaciones" routerLinkActive="router-link-active">

                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <i class="fa-light fa-envelope-open-dollar material-simpli"></i>
                    </div>

                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 texto-menu animate__animated">
                        <input value="Liquidaciones" readonly class="sin-input">
                    </div>
                </div>
            </a>
        </li>




    </ul>
</nav>