<mat-toolbar class="mat-elevation-z3 sticky-toolbar" style="z-index: 2; position: relative; font-family: Poppins; background:white;height: 90px;">

  <img routerLink="/home" src="../../assets/simpli/simplilogo2.svg" height="70px" class="logo-new-simpli"/>


  <button mat-icon-button (click)="open_menu();" class="icon-menu-desktop">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="flex-toolbar" style="position: relative;">


    <div class="logo">
      <button style="z-index: 999999999;" mat-icon-button (click)="open_menu()"><mat-icon style="color:#1B0943;">menu</mat-icon></button>
    </div>

    <span class="example-spacer"></span>


    <div class="user-info">
      <div class="animate__animated animate__fadeIn" style="margin-right: 20px;cursor: pointer;" (click)="cambiarEmpresa()">
        <div class="name_empresa_toolbar1 animate__animated animate__fadeIn"><strong class="animate__animated animate__fadeIn">¡Hola {{name}}!</strong></div>
          <div class="name_empresa_toolbar animate__animated animate__fadeIn" style="width: 140px;">
                <input readonly class="no-input nombre-empresa" value="{{this.company_selected}}">
          </div>
      </div>
    </div>

    <div class="user-icons">


      <button mat-icon-button class="btn-icon-toolbar" matTooltip="Mis credenciales" (click)="openDialog()">
        <span><i class="fa-light fa-shield-keyhole icon-toolbar"></i></span>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="btn-icon-toolbar">
        <span><i class="fa-light fa-user icon-toolbar"></i></span>
      </button>

      <mat-menu #menu="matMenu" xPosition="before" yPosition="below" class="menu-user-simpli">

        <button mat-menu-item routerLink="/home/info">
          <i class="fa-regular fa-user-gear icon-simpli"></i>
          <span style="color: var(--simpli-color5);font-family: 'DM Sans';">Administrar mi cuenta</span>
        </button>

        <button mat-menu-item (click)="openExecutiveInfo()">
          <i class="fa-regular fa-phone-arrow-right icon-simpli"></i>
          <span style="color: var(--simpli-color5);font-family: 'DM Sans';">Contactar a mi Ejecutivo</span>
        </button>


        <button mat-menu-item (click)="cambiarEmpresa()" *ngIf="this.rol != 'conf' && this.rol != 'prpa'">
          <i class="fa-regular fa-buildings icon-simpli"></i>
          <span style="color: var(--simpli-color5);font-family: 'DM Sans';">Gestionar otra Empresa</span>
        </button>


        <button mat-menu-item (click)="gestionarNotificaciones()">
          <i class="fa-regular fa-bell icon-simpli"></i>
          <span style="color: var(--simpli-color5);font-family: 'DM Sans';">Gestionar Notificaciones</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <i class="fa-regular fa-arrow-right-from-bracket icon-simpli"></i>
          <span style="color: var(--simpli-color5);font-family: 'DM Sans';">Cerrar Sesión</span>
        </button>



      </mat-menu>

      <button  mat-icon-button [matMenuTriggerFor]="notificaciones" class="btn-icon-toolbar"  (click)="readNotifications()">
        <span [matBadge]="totalNotificaciones == 0 ? '' : totalNotificaciones" matBadgeColor="warn" matBadgeOverlap="false"><i class="fa-light fa-bell icon-toolbar"></i></span>
      </button>

      <mat-menu #notificaciones="matMenu" xPosition="before" yPosition="below" class="menu-user-simpli add_factoring">
        <div *ngIf="!loadingNotificaciones && notificacionesSimpli.length > 0" class="animate__animated animate__fadeIn animate__faster">

          <a  mat-menu-item *ngFor="let notif of notificacionesSimpli" (click)="readNotification(notif);reloadPage(notif.link)" class="notification-item" style="width: 100%;">
            <div class="notification-item-icon">
              <i class="fa-regular {{notif.icon}}"></i>
            </div>

            <div class="notification-item-body" >
              <div class="notification-item-text">
                <span [innerHTML]="notif.title"></span>
              </div>
              <div class="notification-item-date">
                <small>
                  {{notif.date_created | date:'YYYY-MM-dd, HH:MM'}}
                </small>
              </div>
            </div>
          </a>
        </div>

        <div *ngIf="loadingNotificaciones" style="text-align: center;">
            <div mat-menu-item class="notification-item-spinner" style="text-align: center;justify-content: center;">
              <i class="fa-solid fa-spinner fa-spin-pulse"></i>
            </div>
        </div>

        <div *ngIf="!loadingNotificaciones && notificacionesSimpli.length == 0">
          <div mat-menu-item class="notification-item-no-info">
              No hay notificaciones.
          </div>
        </div>

      </mat-menu>
    </div>
  </div>

  &nbsp;
  &nbsp;
</mat-toolbar>



  <mat-sidenav-container style="min-height: 190vh; " [hasBackdrop]="false" (backdropClick)="close_menu($event)" style="background: #F8F8F8;">


    <mat-sidenav (mouseleave)="mouseleave($event)" style="background: white;" *ngIf="display" class="lale mat-elevation-z3 animate__animated animate__fadeIn animate__faster menusimpli" #sidenav [(mode)]="mode" [(opened)]="opened">


    <app-menu-simpli [menu_cliente]="menu_cliente" [menu_confirming_pronto_pago]="menu_confirming_pronto_pago"></app-menu-simpli>




    </mat-sidenav>


    <mat-sidenav-content style="min-height: 120vh;overflow: hidden;">


    <div class="row row_main"></div>


      <div *ngIf="showTool" class="tooltip_arrayan {{this.tool_class}}" style="display: none;;">
        <div (click)="close_tool()" class="close_tooltip"> &times; </div>
        <div class="tooltip_arrayan_title">
          {{this.tool_title}}
        </div>

        <div class="tooltip_arrayan_body">
            {{this.tool_body}}
        </div>

        <a href="{{this.tool_link}}" class="tooltip_arrayan_link"> {{this.tool_link_text}} </a>
      </div>


      <app-mirai-cliente [initials]="initials" [full_name]="full_name"></app-mirai-cliente>


      <app-chat-bubble *ngIf="activate_chat"></app-chat-bubble>
      <router-outlet>
      </router-outlet>




    </mat-sidenav-content>



</mat-sidenav-container>






