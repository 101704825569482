<mat-expansion-panel class="add-exp animate__animated animate__fadeIn" [disabled]="this.dis">
    <mat-expansion-panel-header style="height:55px !important;min-height:55px !important;max-height:55px !important;position:relative;">
        <mat-panel-title style="font-size: 16px; font-family: Poppins; margin-top: -5px;">
            {{ this.banco }}
        </mat-panel-title>

        <i *ngIf="sw_edit" matTooltip="Es la dirección principal" class="material-icons {{this.star_class}}" (click)="set_principal()">
            {{this.star}}
        </i>
        <i class="material-icons" style="font-size: 22px;  color: #404040; margin-right: 16px;">
            edit
        </i>
        <span class="span-region"> {{this.is_primary_text + ' / ' + this.proveedor}} </span>
    </mat-expansion-panel-header>
    
    <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)">
        <mat-form-field appearance="fill">
        <input type="text" placeholder="Banco" required formControlName="bank" matInput [matAutocomplete]= "auto" /> 
            <mat-autocomplete #auto = "matAutocomplete" (optionSelected)="filtrar_banco($event.option)">
                <mat-option data-id="{{option.id}}" *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-hint>Indique el banco</mat-hint>
            <mat-error *ngIf="valForm.controls['bank'].hasError('required') && (valForm.controls['bank'].dirty || valForm.controls['bank'].touched)">
                    El Banco es <strong>requerido</strong>
            </mat-error>
            
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-select placeholder="Tipos de Cuenta" formControlName="type" [(ngModel)]="this.account_selected" required>
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.id">
                {{cuenta.name}}
                </mat-option>
            </mat-select>
            <mat-hint>Indique el tipo de cuenta</mat-hint>
            <mat-error *ngIf="valForm.controls['type'].hasError('required') && (valForm.controls['type'].dirty || valForm.controls['type'].touched)">
                El tipo de cuenta es <strong>requerido</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <input matInput placeholder="Nombre y Apellido" formControlName="name"  required>
            <mat-hint>Indique el nombre de la cuenta</mat-hint>
            <mat-error *ngIf="valForm.controls['name'].hasError('required') && (valForm.controls['name'].dirty || valForm.controls['name'].touched)">
                El nombre es <strong>requerido</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <input matInput placeholder="Número de cuenta"  (keyup)="transform_number()" formControlName="number" type="text"  required>
            <mat-hint>Indique el número de cuenta</mat-hint>
            <mat-error *ngIf="valForm.controls['number'].hasError('required') && (valForm.controls['number'].dirty || valForm.controls['number'].touched)">
                El número de cuenta es <strong>requerido</strong>
            </mat-error>
            <mat-error *ngIf="valForm.get('number').hasError('pattern') && (valForm.get('number').dirty || valForm.get('number').touched)">
                El número de cuenta debe ser numerico y contener de 6 a 12 digitos
            </mat-error>
        </mat-form-field>
        
        <mat-slide-toggle formControlName="tercero" [(ngModel)]="is_tercero" style="margin-top: 20px; display: flex; font-size: 12px;"> Cuenta de terceros</mat-slide-toggle>
        
        <mat-form-field appearance="fill">
            <input matInput placeholder="RUT" formControlName="rut" [(ngModel)]="run" (keyup)="change_run()" required>
            <mat-hint>Indique el RUT del número de cuenta</mat-hint>
            <mat-error *ngIf="valForm.controls['rut'].hasError('required') && (valForm.controls['rut'].dirty || valForm.controls['rut'].touched)">
                El RUN es <strong>requerido</strong>
            </mat-error>
            <mat-error *ngIf="valForm.get('rut').hasError('pattern') && (valForm.get('rut').dirty || valForm.get('rut').touched)">
                El RUN debe ser en formato DDDDDDD-D Ejm: 17983599-8
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <input matInput placeholder="Email" formControlName="email">
            <mat-hint>Indique el correo electronico de la cuenta</mat-hint>
        </mat-form-field>
       
        
        <div style="margin-top:30px;display:inline-flex;">
            <button *ngIf="sw_edit"  type="submit" [disabled]="disabledButton" mat-flat-button class="primary-button" >Editar</button>
            <button *ngIf="sw_edit"  type="button" (click)="delete_add()" [disabled]="disabled_delete" mat-flat-button class="primary-button">Eliminar</button>
        </div>


    </form>
</mat-expansion-panel>