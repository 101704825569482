<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative">
  <div class="w-100 center-items">
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="padding-left: 0 !important">
      <mat-form-field class="with__underline" id="search__field">
        <input [formControl]="searchCtrl" matInput (keyup)="_filterGeneralByField($event.target.value)"
          placeholder="Filtrar por nombre" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="inline-items">
      <button mat-flat-button matTooltip="Agregar nuevo Colaborador" class="primary-button" (click)="addNewNomina()"
        type="button">
        Agregar Colaborador
      </button>
      <button mat-flat-button class="primary-button" type="button" (click)="openDialogFile()"
        matTooltip="Carga Nomina" style="width: 60px">
        <mat-icon>attach_file</mat-icon>
      </button>
      <button mat-flat-button class="primary-button" type="button" color="primary" (click)="validateDatosPost()"
        >
        Solicitar financiamiento
      </button>

    </div>
  </div>

  <ng-container *ngIf="hasError">
    <div class="container__message">
      <div class="warning-msg animate__animated animate__fadeIn" style="background: white !important; padding-left: 0;">
        <i class="fa-light fa-circle-exclamation" style="color:#ff933b;"></i>
        &nbsp; Revisa los errores en la información proporcionada.
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="noDataFiltered">
    <div style="width: 100%">
      <div class="empty__message">
        <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 20px"></i>
        <p>No hay resultados para la busqueda</p>
      </div>
    </div>
  </ng-container>

  <div class="table-responsive-simpli add_factoring" style="margin-top: 20px">

      <ng-container *ngIf="noDataRow">
            <div class="container__message">
              <div class="warning-msg animate__animated animate__fadeIn" style="background: white !important; padding-left: 0;">
                <i class="fa-light fa-circle-exclamation" style="color:#ff933b;"></i>
                &nbsp; Debes agregar al menos un colaborar para continuar.
              </div>
            </div>
      </ng-container>

    <form [formGroup]="form" *ngIf="dataSource.data.length > 0 || !noDataFiltered || !noDataRow">
      <div class="table-responsive-simpli add_factoring">

        <table mat-table [dataSource]="dataSource" formArrayName="nomina" style="width: 100%">
          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns"></tr>


          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef style="width: 50px !important;"></th>
            <td style="width: 50px !important;" mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <div class="input__icon">
                <span matTooltip="Eliminar Colaborador" (click)="deleteEmpleado(row,index)">
                  <i class="fa-light fa-minus-circle deleteicon"></i>
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <div class="input__icon">
                <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                  <input matInput class="no-input" formControlName="name" placeholder="Nombre" required />
                  <mat-error
                    *ngIf="validateField('name',row,index,'errorName') || !validateIncorrect('name',row,index,'errorName')">
                    El nombre es <strong>requerido</strong>
                  </mat-error>
                  <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('name',row,index,'errorName')">
                    El nombre es <strong>incorrecto</strong>
                  </mat-hint>
                </mat-form-field>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef>Rut</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                <input class="no-input" matInput formControlName="rut" placeholder="Rut" required
                  (keyup)="onChangeInputRut($event, row, index)" />
                <mat-error
                  *ngIf="validateField('rut',row,index,'errorRut') || !validateIncorrect('rut',row,index,'errorRut')">
                  El rut es <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('rut',row,index,'errorRut')">
                  El rut es <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>Cuenta</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">

                <input matInput class="no-input" formControlName="number" placeholder="N.Cuenta" required />
                <mat-error
                  *ngIf="validateField('number',row,index,'errorNumber') || !validateIncorrect('number',row,index,'errorNumber')">
                  El número de cuenta es <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('number',row,index,'errorNumber')">
                  El número de cuenta es <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                <input matInput class="no-input" type="email" formControlName="email" placeholder="Email" required />
                <mat-error
                  *ngIf="validateField('email',row,index,'errorEmail') || !validateIncorrect('email',row,index,'errorEmail')">
                  El email es <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('email',row,index,'errorEmail')">
                  Formato de email <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Monto</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                <input matInput class="no-input" formControlName="amount" placeholder="Monto"
                  (keyup)="onChangeInput($event, row, index)" required />
                <mat-error
                  *ngIf="validateField('amount',row,index,'errorAmount') || !validateIncorrect('amount',row,index,'errorAmount')">
                  El monto es <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('amount',row,index,'errorAmount')">
                  Monto <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="banco">
            <th mat-header-cell *matHeaderCellDef style="width: 200px">Banco</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                <mat-select formControlName="id_bank" placeholder="Banco">
                  <mat-option *ngFor="let banco of bancos" [value]="banco.id">
                    {{ banco.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="validateField('id_bank',row,index,'errorBank') || !validateIncorrect('id_bank',row,index,'errorBank')">
                  El banco es <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('id_bank',row,index,'errorBank')">
                  Banco <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef style="width: 200px">
              Tipo de Cuenta
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="getActualIndex(index)">
              <mat-form-field class="mat-sm" [floatLabel]="labelFloat" appearance="fill">
                <mat-select formControlName="id_type" placeholder="Tipo de Cuenta">
                  <mat-option *ngFor="let type of accountType" [value]="type.id">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="validateField('id_type',row,index,'errorType') || !validateIncorrect('id_type',row,index,'errorType')">
                  Tipo de cuenta <strong>requerido</strong>
                </mat-error>
                <mat-hint align="center" class="mat-error" *ngIf="validateIncorrect('id_type',row,index,'errorType')">
                  Tipo de cuenta <strong>incorrecto</strong>
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; let index = index; let i = index" style="text-align: center"
              matTooltip="{{getMatTooltipError(row)}}">
              <i class="fa-light fa-check-circle" style="color: #3BA46F" *ngIf="
                                  row.value.error == false &&
                                  row.value.errorRemuneraciones == false
                                "></i>
              <i class="fa-light fa-exclamation-circle" style="color: #ff933b" *ngIf="
                                  row.value.error == true &&
                                  row.value.errorRemuneraciones == false
                                "></i>
              <i class="fa-light fa-times-circle" style="color: #fa454c" *ngIf="
                                  row.value.error == true && row.value.errorRemuneraciones == true
                                "></i>
            </td>
          </ng-container>


        </table>

      </div>

    </form>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

</div>
