import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ClienteNuevoComponent } from './home/cliente-nuevo/clientenuevo.component';
import { ConcentracionFactoring } from './home/dashboard/concentracion-factoring/concentracion.factoring';
import { Dashboard } from './home/dashboard/dashboard';
import { DashboardCompras } from './home/dashboard/dashboard-compras/dashboard-compras';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RegistroEmpresaComponent } from './registro-empresa/registro-empresa.component';
import { InfoComponent } from './home/info/info.component';
import { PasswordComponent } from './home/info/password/password.component';
import { CuentasBancariasComponent } from './home/info/ctas-bancarias/cuentas-bancarias';
import { OpProveedores } from './home/operaciones-proveedores/op-proveedores';
import { OperacionesRemuneracionesComponent } from './home/operaciones-remuneraciones/operaciones-remuneraciones.component';
import { PPFacturasPagadasComponent } from './home/payments/facturas-pagadas/pp-facturas-pagadas.component';
import { PagoProveedores } from './home/payments/pago-proveedores/pago-proveedores';
import { ProveedoresFechasComponent } from './home/payments/proveedores-fechas/proveedores-fechas.component';
import { PagoRemuneracionesPageComponent } from './home/payments/remuneraciones/page/pago-remuneraciones-page.component';
import { ExcedentesParrillaComponent } from './home/excedentes-parrilla/excedentes-parrilla.component';
import { OperacionesAlianzadosComponent } from './home/operaciones-alianzados/operaciones-alianzados.component';
import { DetalleCarteraComponent } from './home/detalle-cartera/detalle-cartera.component';
import { FacturasPagadasComponent } from './home/facturas-pagadas/facturas-pagadas.component';
import { FacturasArrayanComponent } from './home/facturas-arrayan/facturas-arrayan.component';
import { LiquidacionesComponent } from './home/liquidaciones/liquidaciones.component';
import { ListaEmpresasComponent } from './home/lista-empresas/lista-empresas.component';
import { PuenteClienteComponent } from './puente-cliente/puente-cliente.component';
import { CesionFacturadorComponent } from './home/cliente-new-version/cesion-facturador/cesion-facturador.component';
import { StartSimulationComponent } from './home/cliente-new-version/start-simulation/start-simulation';
import { ParrillaSimulacionsComponent } from './home/cliente-new-version/parrilla-simulaciones/parrilla-simulaciones.component';
import { VerSimulacionComponent } from './home/cliente-new-version/ver-simulacion/ver-simulacion';
import { VerSimulacionRechazadaComponent } from './home/cliente-new-version/ver-simulacion-rechazada/ver-simulacion-rechazada';
import { DocumentacionComponent } from './home/cliente-new-version/documentacion/documentacion';
import { InfoDocumentacionComponent } from './home/cliente-new-version/documentacion/info-documentacion/info-documentacion';
import { SimularFacturasResponsive } from './home/cliente-new-responsive/simular-facturas-responsive/simular-facturas-responsive';
import { ParrillaSimulacionsResponsiveComponent } from './home/cliente-new-responsive/parrilla-simulaciones-responsive/parrilla-simulaciones-responsive.component';
import { InfoCertificadoComponent } from './home/cliente-new-version/cesion-facturador/cargar-certificado/info-certificado/info-certificado';
import { ParrillaCartola } from './home/cliente-new-version/parrilla-cartola/parrilla-cartola';
import { ResumenFacturasComponent } from './home/cliente-new-version/parrilla-cartola/resumen-facturas/resumen-facturas';
import { ParrillaCartolaResponsiveComponent } from './home/cliente-new-responsive/parrilla-cartola-responsive/parrilla-cartola-responsive';
import { ParrillaFacturasResponsiveComponent } from './home/cliente-new-responsive/parrilla-facturas-responsive/parrilla-facturas-responsive';
import { ParrillaOperaciones } from './home/cliente-new-version/parrilla-operaciones/operaciones';
import { DetailOperationComponent } from './home/cliente-new-version/parrilla-operaciones/detalle-operacion/detalle-operacion';
import { InfoSyncComponent } from './home/cliente-new-version/cesion-facturador/pantalla-info-sync/pantalla-info-sync';
import { CesionAsistida } from './cesion-asistida/cesion-asistida';
import { DetailOperationParcialComponent } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/detalle-operacion-parcial/detalle-operacion-parcial';
import { FacturasdelSii } from './home/cliente-new-version/facturas-del-sii/facturas-del-sii';
import { ContinuarOperacionComponent } from './home/cliente-new-version/parrilla-operaciones/dialog-cesion-parcial/continuar-operacion/continuar-operacion';




export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:id', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'recover-password/:id', component: RecoverPasswordComponent },
  { path: 'cesion-asistida/:id', component: CesionAsistida },
  { path: 'registro-empresa', component: RegistroEmpresaComponent },
  { path: 'registro-empresa/:id_alianza', component: RegistroEmpresaComponent },
  { path: 'portales', component: PuenteClienteComponent},
  //enlace cartola al ejecutivo
  { path: 'cartola-cliente/:id/:role/:token/:company', component: ParrillaCartola },
  { path: 'facturas-cliente/:id', component: ResumenFacturasComponent },

  //end enlace
  { path: 'home', component: LayoutComponent,
      children: [
        { path: '', component: ClienteNuevoComponent},
        { path: 'contacto', component: ClienteNuevoComponent},
        { path: 'home/home', component: ClienteNuevoComponent},
        { path: 'resumen-linea', component: ClienteNuevoComponent},
        { path: 'dashboard-compras', component: DashboardCompras },
        { path: 'dashboard-compras/:id', component: DashboardCompras},
        { path: 'dashboard', component: Dashboard },
        { path: 'dashboard/:id', component: Dashboard},
        { path: 'concentracion-factoring', component: ConcentracionFactoring},
        { path: 'simulaciones', component: ParrillaSimulacionsComponent},
        { path: 'simulaciones/:id', component: ParrillaSimulacionsComponent},
        { path: 'info/:id', component: InfoComponent},
        { path: 'info', component: InfoComponent},
        { path: 'change-password', component: PasswordComponent },
        { path: 'cuentas-bancarias', component: CuentasBancariasComponent},
        { path: 'cuentas-bancarias/:id_company', component: CuentasBancariasComponent},
        { path: 'excedentes', component: ExcedentesParrillaComponent },
        { path: 'excedentes/:id', component: ExcedentesParrillaComponent},  
        { path: 'operaciones', component: ParrillaOperaciones },  
        { path: 'operaciones/:id_company', component: ParrillaOperaciones },  
        { path: 'operaciones-alianzados', component: OperacionesAlianzadosComponent },   
        { path: 'operaciones-proveedores', component: OpProveedores},
        { path: 'operaciones-proveedores/:id_company', component: OpProveedores},
        { path: 'operaciones-remuneraciones', component: OperacionesRemuneracionesComponent},
        { path: 'operaciones-remuneraciones:/id_company', component: OperacionesRemuneracionesComponent},
        { path: 'pago-remuneraciones', component: PagoRemuneracionesPageComponent},
        { path: 'pago-remuneraciones/:id_company', component: PagoRemuneracionesPageComponent},
        { path: 'pp-facturas-pagadas', component: PPFacturasPagadasComponent},
        { path: 'pp-facturas-pagadas/:id_company', component: PPFacturasPagadasComponent},
        { path: 'pago-proveedores', component: PagoProveedores},
        { path: 'pago-proveedores/:id_company', component: PagoProveedores},
        { path: 'pago-proveedores-fechas', component: ProveedoresFechasComponent},
        { path: 'pago-proveedores-fechas/:id_company', component: ProveedoresFechasComponent},
        { path: 'detalle-cartera', component: DetalleCarteraComponent},
        { path: 'detalle-cartera/:id', component: DetalleCarteraComponent},
        { path: 'facturas-pagadas', component: FacturasPagadasComponent},
        { path: 'facturas-arrayan', component: FacturasArrayanComponent},
        { path: 'facturas-pagadas/:id', component: FacturasPagadasComponent},
        { path: 'liquidaciones', component: LiquidacionesComponent },
        { path: 'liquidaciones/:id', component: LiquidacionesComponent },
        { path: 'empresas-alianzados', component:ListaEmpresasComponent },
        { path: 'confliquidaciones', component: LiquidacionesComponent },
        { path: 'confliquidaciones/:id', component: LiquidacionesComponent },
        { path: 'new-client', component: ClienteNuevoComponent},
        { path: 'new-client/:id', component: ClienteNuevoComponent},

        //NEW CLIENT 3.0
        { path: 'detalle-operacion/:id', component: CesionFacturadorComponent },
        { path: 'continuar-operacion/:id', component: ContinuarOperacionComponent },
        { path: 'ver-detalle/:id', component: VerSimulacionComponent },
        { path: 'ver-detalle-rechazada/:id', component: VerSimulacionRechazadaComponent },
        { path: 'documentacion/:id', component: DocumentacionComponent },
        { path: 'documentacion/:id/:op/:caso', component: DocumentacionComponent },
        { path: 'info-documentacion/:id', component: InfoDocumentacionComponent },
        { path: 'comenzar-simulacion', component: StartSimulationComponent },
        { path: 'info-certificado', component: InfoCertificadoComponent },
        { path: 'cartola', component: ParrillaCartola },
        { path: 'cartola/:id', component: ParrillaCartola },
        { path: 'resumen-facturas/:id', component: ResumenFacturasComponent },
        { path: 'operation-detail/:id', component: DetailOperationComponent },
        { path: 'operation-detail-parcial/:id/:id_sim', component: DetailOperationParcialComponent },
        { path: 'continue-operation-detail-parcial/:id/:id_sim/:continue', component: DetailOperationParcialComponent },
        { path: 'agregar-facturas/:id/:id_sim', component: FacturasdelSii },
        { path: 'info-sync', component: InfoSyncComponent },

        //NEW CLIENTE RESPONSIVE
        { path: 'simular-facturas-r/:id', component: SimularFacturasResponsive },
        { path: 'simulaciones-r/:id', component: ParrillaSimulacionsResponsiveComponent },
        { path: 'cartola-r', component: ParrillaCartolaResponsiveComponent},
        { path: 'cartola-r/:id', component: ParrillaCartolaResponsiveComponent},
        { path: 'resumen-facturas-r/:id', component: ParrillaFacturasResponsiveComponent },
      ],
    },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
