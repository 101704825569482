import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
declare var $ :any;

@Component({
  selector: 'app-liquidaciones',
  templateUrl: './liquidaciones.component.html',
  styleUrls: ['./liquidaciones.component.less']
})
export class LiquidacionesComponent implements OnInit {
  id_company: any
  role: any

  has_liquidacion = true
  has_operaciones=false

  operaciones=[]

  liquidaciones = []
    id_liquidacion: any;

    fecha_hoy: any; 

    has_liquidaciones:boolean=false;;

    ELEMENT_DATA: Array<any> = []

    page:any=1
    length = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [20];

    displayedColumns: string[] = ['position'];
    dataSource = new MatTableDataSource(this.ELEMENT_DATA);

    liqui_spinner:boolean=true
    liqui_spinner2:boolean=true
    show_spinner = false
    filter=''
    fecha_filter=""

    @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public dialog:MatDialog,fb: FormBuilder, private activated_router: ActivatedRoute, public settings: SettingsService, private router: Router) { 

    this.activated_router.params.subscribe((params:any) => {
      //this.id_company = params['id']
    })

    this.id_company = localStorage.getItem("company_id")

    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.ngOnInit()
      }
    });
  }

  currency(value: any)
    {
      value = new Intl.NumberFormat('es-CL',{style: 'currency', currency:'CLP'}).format(value);
      return value
    }

  
  ngOnInit() {
    this.id_company = localStorage.getItem("company_id")

    this.role=localStorage.getItem("role")

    let hoy = new Date();

    let dia = hoy.getDate()
    let mes = hoy.getMonth() + 1

    let year = hoy.getFullYear()

    let hour = hoy.getHours()

    let min = hoy.getMinutes()

    let min_str = min.toString()

    if (min < 10)
      min_str = "0" + min

    this.fecha_hoy = dia + "." + mes + "." + year + " " + hour +":"+min_str  

    console.log("holaaa")
    this.load_liquidaciones(1)

  }


  



  open_more_box(c: any,index: any, sw = false)
  {
    let prefix = ""
    console.log("esto se abrio")
   
    if( this.ELEMENT_DATA[index]["operaciones"]==null ||  this.ELEMENT_DATA[index]["operaciones"]==undefined ){
      this.getOperaciones(c,index)
    }
    

    if (sw)
      prefix = "long_"

    if ($("#"+prefix+"body_box_"+c).hasClass("body-active"))
    {
      $("#"+prefix+"body_box_"+c).removeClass("body-active")
      $("#"+prefix+"sel_arrow_"+c).removeClass("arrow_active")
    }
    else
    {
      $("#"+prefix+"body_box_"+c).addClass("body-active")
      $("#"+prefix+"sel_arrow_"+c).addClass("arrow_active")
    }
  }

  formatMoney(amount: any, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) ;
    } 
    catch (e) {}
    }


  async getOperaciones(id: any,index: any) {
    try {

      this.liquidaciones = []
      this.has_liquidacion = true;

      const bd: any = await this.settings.get_query(1, 'operacionesget/' + id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

            this.ELEMENT_DATA[index]["operaciones"]=body_data.operaciones[0] ;

            if(body_data.operaciones.length > 0){
                this.has_operaciones = true;
                this.liqui_spinner2=false

            }else{

                this.has_operaciones = false;
                this.liqui_spinner2=false
            }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  applyFilter(filtro: any){
      this.filter = filtro
      this.page=1
      this.length= 0;
      // this.paginator.firstPage();
  
      // this.paginator.pageIndex = 0;
      this.load_liquidaciones(1)
    }

    changeFecha(fecha: any){
      console.log(fecha)
      if(fecha!=null && fecha!=undefined && fecha!=''){
        let splt_f = fecha.split('/')
        let fecha_format = new Date(`${splt_f[2]}-${splt_f[1]}-${splt_f[0]}`)
        this.fecha_filter=`${fecha_format.getFullYear()}-${this.format_month_number(fecha_format.getMonth()+1)}-${fecha_format.getDate()}`
      }else{
        this.fecha_filter=''
      }
      this.page=1
      this.length= 0;
      this.load_liquidaciones(1)
    }

    format_month_number(month: any){
      switch (month) {
        case 1:
          return "01"
          break;
        case 2:
          return "02"
        
        case 3:
          return "03"
        case 4:
          return "04"
        case 5:
          return "05"
        case 6:
          return "06"
        case 7:
          return "07"

        case 8:
          return "08"
        case 9:
          return "09"
      
        default:
          return month
      }
    }




  async print(id: any) {
    try {

      this.show_spinner = true


      const bd: any = await this.settings.get_query(1, 'printotorgamiento/' + id+'/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

              let file=body_data.file
              let file_name=body_data.file_name

              
              this.show_spinner = false
              let anchor = document.createElement('a');
              anchor.href = file;
              anchor.target = '_blank';
              anchor.download = file_name;
              anchor.click();


          break;
        default:

          break;
      }
    } catch (bd: any) {
              this.show_spinner = false
    }
  }

  pageCustom($e: any)
  {
    //this.ELEMENT_DATA = []
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource._updateChangeSubscription();
    this.page=$e.pageIndex + 1
    this.load_liquidaciones(this.page)
  }

  async load_liquidaciones(page: any) {
    try {

      this.liquidaciones = []
      this.has_liquidacion = true;
      let tipo_doc=''
      if(this.role=='clie'){
        tipo_doc='clie'
      }else if(this.role=='admi'){
        tipo_doc='admi'
      }
      else{
        tipo_doc='conf'
      }

      const bd: any = await this.settings.get_query(1, 'getotorgamiento/' + this.id_company + '/?pageNumber=' +  page+'&filtro='+this.filter+'&fecha='+this.fecha_filter+'&tipo_doc='+tipo_doc)
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

              this.liquidaciones = body_data.liquidaciones;

              if(this.liquidaciones.length > 0){
                  this.has_liquidacion = true;
                  this.ELEMENT_DATA=this.liquidaciones
                  this.length=body_data.total
                  
              }else{
                  this.has_liquidacion = false;
              }

              if(this.liqui_spinner){
                this.liqui_spinner=false
              }



          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }



  open_credenciales(){
    this.router.navigate(['/', 'home'])
    .then(nav => {

      setTimeout(() => {
        $("#caja_excel").trigger('click')
      });

    }, err => {
      console.log(err) 
    });
  }


}
