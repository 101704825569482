import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PasswordComponent } from "../password/password.component";
import { CredencialesSII } from "src/app/credenciales-sii/credenciales-sii";
import { SettingsService } from "src/app/settings/settings.service";
import { MatDialog } from "@angular/material/dialog";
declare var $ :any;


@Component({
  selector: "app-cuentas-bancarias-component",
  templateUrl: "./cuentas-bancarias.html",
  styleUrls: ["./cuentas-bancarias.less"],
})
export class CuentasBancariasComponent implements OnInit {

  id_company:any;
  @Output() funciones = new EventEmitter<number>();

  constructor(
    private router: Router,
    public settings: SettingsService,
    private dialog:MatDialog) { }

    myObserver:any;
    ngOnDestroy() {
      this.myObserver.unsubscribe();
    }

  ngOnInit() {

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });
    this.load_all();

  }

  load_all() {
    this.id_company = localStorage.getItem("company_id");

  }



  actualizar=0;
  procesaPropagar($e:any){
    console.log("Componente cuentas:", $e)
    this.actualizar = $e;
  }

  async download() {
    try {
      const bd: any = await this.settings.get_query(1, 'ConfirmigAcreedoresBank/' + this.id_company + "/");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

          window.open(body_data.nameArchivo, "blank")


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  upload(){

    $("#file_but").trigger("click");
  }

  success:boolean=false;
  warning:boolean=false;
  disabled:boolean=false;
  async upload_file($e:any) {
    try {

      this.success=false;
      this.warning=false;
      this.disabled=true;
  
      let formData: FormData = new FormData();
      let file_input = $e.target.files;
  
      for(let i=0;i<file_input.length;i++){
        let name = file_input[i].name
        formData.append('ctas_'+i, file_input[i], name);
      }

      const bd: any = await this.settings.get_query(2, 'ConfirmigAcreedoresBank/' + this.id_company +'/', formData);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        this.disabled = false;

            

        if (body_data.error.length != 0) {
          this.warning = true;
        } else {
          this.success = true;
          this.actualizar =1 
        }


          break;
        default:

          break;
      }
    } catch (bd: any) {
      this.warning = false;
      this.disabled = false;
    }
  }


  openDialog() {
    console.log("open")
    const dialogRef = this.dialog.open(CredencialesSII,{
      width:'600px',
      autoFocus: false
    });
    dialogRef.componentInstance.id_company = this.id_company

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }


  changePassword() {
    console.log("open")
    const dialogRef = this.dialog.open(PasswordComponent,{
      width: '700px'
    });
    dialogRef.componentInstance.open_as_dialog = true;
 
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  
}
