<div class="row row_main">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Excedentes Disponibles : &nbsp; ${{formatMoney(this.excedentes_totales)}}

      <span *ngIf="solicitar_excedentes == true" style="margin-left: 20px;"> 

        <button matTooltip="Solicitar Excedentes" [disabled]="loading_excentes" class='primary-button' mat-flat-button  style="margin-top: 15px;display: none;" (click)="sol_excedentes()">
          <span *ngIf="loading_excentes == false">Solicitar</span>
          <span *ngIf="loading_excentes == true"><i class="fa-light fa-spinner fa-spin-pulse"></i></span>
        </button>


      </span>

    </h1>
    <small class="subtitulo-simpli">{{this.ultima_fecha}} 
      

    </small>

  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margen">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <div class="row">

          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
            <mat-form-field>
              <mat-label>Clientes</mat-label>
              <mat-select [(ngModel)]="selected_value1" multiple (selectionChange)="filtros(2, $event)">
                <mat-option *ngFor="let deu of deudores; let i=index" [value]="deu.deudor">{{deu.deudor}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
            <mat-form-field>
              <mat-label>N.º Facturas</mat-label>
              <mat-select multiple [(ngModel)]="selected_value2" (selectionChange)="filtros(1, $event)">
                <mat-option *ngFor="let docto of facturas" [value]="docto.numero_factura">N.º Facturas: {{docto.numero_factura}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
            <mat-form-field>
              <mat-label>Mes</mat-label>
              <mat-select [(ngModel)]="selected_value3" (selectionChange)="filtros(3, $event)">
                <mat-option *ngFor="let m of mes" [value]="m">{{format_month(m)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
            <mat-form-field>
              <mat-label>Año</mat-label>
              <mat-select [(ngModel)]="selected_value4" (selectionChange)="filtros(4, $event)">
                <mat-option *ngFor="let y of year" [value]="y">{{y}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2 btn-reset-filtro">
            <!-- <mat-icon id="RESET" (click)="reset_filtros()" matTooltip="Limpiar filtros" style="cursor: pointer;
            color: #f33636;
            font-size: 28px;">replay</mat-icon> -->
            <button class='primary-button' mat-flat-button id="RESET" (click)="reset_filtros()" style="margin-top: 15px;">Limpiar filtros</button>
          </div>

        </div>

      </div>
    </div>
    

    <mat-spinner class="spinner-simpli" diameter="30" *ngIf="status_spinner==true" style="margin-top: 150px;"></mat-spinner>

    <div *ngIf="status_spinner == false">

      <div class="sinRegistros" style="text-align: center;margin-top: 30px;" *ngIf="this.has_excedentes == false">
        <small class="small-sinregistros" style="text-align: center;padding-top: 40px;padding-bottom: 40px;">
          <div style="text-align: center;margin-bottom: 20px;">
              <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
          </div>
          Todavía no tienes historial de excedentes. Simula una operación para simplificar tus finanzas. <br>
          <strong>¿Simular ahora?</strong>              
        <br />
        <br />
        <div style="text-align: center;">
          <button  mat-flat-button class="primary-button" (click)="open_credenciales()" type="button">Vamos</button>
        </div>
       
      </small>
      </div>

    </div>


    <mat-card style="box-shadow: none;background: none;" *ngIf="this.has_excedentes == true && !status_spinner">

      <mat-card-content>
          <div style="width: 100%;">
            <div class="row caja_docto opacidad opacidad{{i}} padding-responsive" *ngFor="let info of excedentes;let i = index;"> 
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">    
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding-left: 0;">
                    <small class="texto_tabla factura"> N.º Factura</small>
                    <small class="texto_tabla factura" style="font-weight: 600;color: #4d4d4d;"> {{info.numero_factura}}</small>
                  </div>
            
                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-top caja_tabla phon_column margen_responsive caja_tabla_responsive">
                    <small class="texto_tabla">N.º Factura</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.numero_factura}}</small>
                  </div>
      
                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-top caja_tabla phon_column margen_responsive caja_tabla_responsive">
                    <small class="texto_tabla">N.º Operacion</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.numero_operacion}}</small>
                  </div>
      
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column caja_tabla_responsive">
                    <small class="texto_tabla">Excedentes</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.excedentes)}}</small>
                  </div>
      
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column  caja_tabla_responsive">
                    <small class="texto_tabla">Dias de Vencimiento</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.dias_mora}}</small>
                  </div>
            
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column caja_tabla_small caja_tabla_responsive">
                    <small class="texto_tabla">Monto Total</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_documento)}}</small>
                  </div>
      
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column caja_tabla_small caja_tabla_responsive">
                    <small class="texto_tabla">Monto Anticipo</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_anticipado)}}</small>
                  </div>
      
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column caja_tabla_bottom caja_tabla_responsive">
                    <small class="texto_tabla">Monto Pagado</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_pagado)}}</small>
                  </div>
      
                  <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6 col-top caja_tabla phon_column caja_tabla_bottom caja_tabla_responsive">
                    <small class="texto_tabla">Interes Mora</small>
                    <br>
                    <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.interes_mora)}}</small>
                  </div>
      
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-top caja_tabla phon_column caja_tabla_fechas">
                    <small class="texto_tabla">Fecha Otor.</small>
                    <br>
                    <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_otorgamiento | date: 'dd.MM.yyyy'}}</small>
                    <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_otorgamiento | date: 'dd.MM.yy'}}</small>
                  </div>
            
            
      
                </div>
                
                <div class="row fechas margen_inferior">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="text-align: center;">
                    <button class="botones_fechas">Fecha Otorgamiento: {{info.fecha_otorgamiento | date: 'dd.MM.yyyy'}}</button>
                    <button class="botones_fechas">Fecha Vencimiento: {{info.fecha_vencimiento | date: 'dd.MM.yyyy'}}</button>
                    <button class="botones_fechas">Fecha Pago.: {{info.fecha_pago | date: 'dd.MM.yyyy'}}</button>
                  </div>
                </div>
      
                <!-- <div class="row linea">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="margin-bottom: 0;">
                    <hr style="width: 101%;float: left;margin-top: 1px;color: #858489;margin-left: -15px;"/>
                  </div>
                </div> -->
      
                <div class="row expand_row" style="margin-top: -5px;padding-bottom: 10px;">
                  <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="display:flex;justify-content:flex-end">
                    <div class="menu-box-container">
                            
                      <div (click)="open_more_box(info.id)" class="button-box button-down ">
                        <i id="sel_arrow_{{info.id}}" class="material-icons boton_arrow_box {{i.class_arrow}}" style="color: black; cursor:pointer;">
                          keyboard_arrow_down
                        </i>
                      </div>
                    </div>
                    
      
                  </div> -->
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div id="body_box_{{info.id}}" class="body-active box-prospecto-responsive-body {{i.class}}" style="background: white;width: 100% !important;">
                     <div class="row">
      
                       <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11">
                         <div class="row">
      
                          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla margen_responsive caja_tabla_responsive">
                                <small class="texto_tabla">N.º Operacion</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.numero_operacion}}</small>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla caja_tabla_small caja_tabla_responsive">
                                <small class="texto_tabla">Monto Total</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_documento)}}</small>
                              </div>
          
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla caja_tabla_small caja_tabla_responsive">
                                <small class="texto_tabla">Monto Anticipo</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_anticipado)}}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla caja_tabla_bottom excedentes caja_tabla_responsive">
                                <small class="texto_tabla">Excedentes:</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.excedentes)}}</small>
                              </div>
                  
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla caja_tabla_bottom caja_tabla_responsive">
                                <small class="texto_tabla">Monto Pagado</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.monto_pagado)}}</small>
                              </div>
          
                              <div class="col-lg-4 col-md-4 col-sm-3 col-xs-6 col-top caja_tabla caja_tabla_bottom caja_tabla_responsive">
                                <small class="texto_tabla">Interes Mora</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">${{formatMoney(info.interes_mora)}}</small>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha Otorgamiento</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_otorgamiento | date: 'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_otorgamiento | date: 'dd.MM.yy'}}</small>
                              </div>
          
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha Pago</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pago | date: 'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pago | date: 'dd.MM.yy'}}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                                <small class="texto_tabla">Fecha Vencimiento</small>
                                <br>
                                <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_vencimiento | date: 'dd.MM.yyyy'}}</small>
                                <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_vencimiento | date: 'dd.MM.yy'}}</small>
                              </div>
          
                              <div class="col-lg-6 col-md-6 col-sm-3 col-xs-6 col-top caja_tabla  caja_tabla_responsive">
                                <small class="texto_tabla">Dias Vencimiento</small>
                                <br>
                                <small class="texto_tabla" style="font-weight: 600;color: #4d4d4d;">{{info.dias_mora}}</small>
                              </div>
                            </div>
                          </div>
      
                         </div>
         
                        
                       </div>
                       
                       <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                         <div class="row">
      
                          <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                            <small class="texto_tabla">Fecha Pago</small>
                            <br>
                            <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pago | date: 'dd.MM.yyyy'}}</small>
                            <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_pago | date: 'dd.MM.yy'}}</small>
                          </div>
                    
                          <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 col-top caja_tabla caja_tabla_fechas">
                            <small class="texto_tabla">Fecha Vcto.</small>
                            <br>
                            <small class="texto_tabla fecha_larga" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_vencimiento | date: 'dd.MM.yyyy'}}</small>
                            <small class="texto_tabla fecha_corta" style="font-weight: 600;color: #4d4d4d;">{{info.fecha_vencimiento | date: 'dd.MM.yy'}}</small>
                          </div>
                         </div>
                       </div> -->
                     </div>
                    </div>
                  </div>
                </div> 
              </div> 
            </div>
           
          </div>

        <mat-paginator id="hidePaginator" #paginator [length]="length" [pageSize]="10" (page)="pageCustom($event)"></mat-paginator>
       

      </mat-card-content>

    </mat-card>

 
  </div>
</div>