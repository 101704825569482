import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;


@Component({
  selector: 'cambiar-empresa',
  templateUrl: './cambiar-empresa.html',
  styleUrls: ['./cambiar-empresa.less']
})


export class CambiarEmpresa implements OnInit {
   
  element:any;


  constructor(public snackBar: MatSnackBar, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService){

  }


  stateCtrl = new FormControl();
  filteredStates!: Observable<State[]>;
  toHighlight: string = '';

  name1:string="Miguel Rojas"
  name2:string="Roger Perdomo"
  name3:string="Jose Castro"
  name4:string="Rafaela Solis"

  states:State[]=[]
  public selected2:any;
  rol:any;

  ngOnInit() {

    this.rol = localStorage.getItem('role')

    this.empresas_relacionadas()


  }


  myControl = new FormControl();
  filteredOptions!: Observable<string[]>;
  options: State[] = [];

  filterStates(name: string) {
    this.toHighlight = name;
    return this.states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  company_selected="";
  load:boolean=false;
  async load_companies() {
    try {
      this.options = [];
      this.states = [];
  
      this.load = true;

      const bd: any = await this.settings.get_query(1, '/cliente/empresas-relacionadas/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        let company_id = "";

        console.log(body_data)

        for(let i=0;i<body_data.success.info.length;i++){
          let id=body_data.success.info[i].company_id;
          let name=body_data.success.info[i].business_name;
          let rut=body_data.success.info[i].rut;


          this.states.push({
            id:id, name:name,rut:rut
          })
          this.options.push({
            id:id, name:name,rut:rut
          })

          this.filteredStates = this.stateCtrl.valueChanges
          .pipe(
            startWith(''),
            map((state:any) => state ? this.filterStates(state) : this.states.slice())
          );


        }

        if (body_data.success.info.length > 0)
        {
          
          let company_id = Number(localStorage.getItem('company_id'))
          this.selected2 = Number(localStorage.getItem('company_id')) //company_id

          let ttt = body_data.success.info.find((obj:any) => obj.company_id == company_id)
          if (ttt != null){
            this.myControl.setValue(ttt.business_name)
            this.company_selected = ttt.business_name;
            this.element.company_selected = (this.TextAbstract(ttt.business_name, 75)).toUpperCase();
          }

        
          
        }


        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          map((value:any) => this._filter(value))
        );




        
        
          this.load = false;



          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  findObjectByKey(array:any, key:any, value:any) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
  }


  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue) === true).slice(0, 100);;
  }

  reset(){
    $("#buscador").val("")
  }

  selected = ($e:any) => {

    let id_company = $e.option.id
    let ruta = this.router.url
    let rutas = ruta.split("/")
    localStorage.setItem('company_id', id_company)

    this.set_storage_detail_final();

    this.empresas_relacionadas()

    if (rutas[2] == "info") 
    {
      this.router.navigate(['home/info/'+id_company])
    }

    if (rutas[2] == "new-client" || rutas[2] == undefined) 
    {
      this.router.navigate(['home/new-client/'+id_company])
    }

    if (rutas[2] == "detalle-cartera")
    {
      this.router.navigate(['home/detalle-cartera/'+id_company])
    }

    if (rutas[2] == "simulaciones")
    {
      this.router.navigate(['home/simulaciones/'+id_company])
    }

    if (rutas[2] == "liquidaciones")
    {
      this.router.navigate(['home/liquidaciones/'+id_company])
    }

    if (rutas[2] == "facturas-pagadas")
    {
      this.router.navigate(['home/facturas-pagadas/'+id_company])
    }

    if (rutas[2] == "excedentes")
    {
      this.router.navigate(['home/excedentes/'+id_company])
    }

    if (rutas[2] == "dashboard")
    {
      this.router.navigate(['home/dashboard/'+id_company])
    }

    if(rutas[2] == 'cuentas-bancarias'){
      this.router.navigate(['home/cuentas-bancarias/'+id_company])
    }

    if(rutas[2] == 'pago-proveedores'){
      this.router.navigate(['home/pago-proveedores/'+id_company])
    }

    if(rutas[2] == 'pago-proveedores-fechas'){
      this.router.navigate(['home/pago-proveedores-fechas/'+id_company])
    }

    if(rutas[2] == 'operaciones-proveedores'){
      this.router.navigate(['home/operaciones-proveedores/'+id_company])
    }

    if (rutas[2] == 'pago-remuneraciones') {
      this.router.navigate(['home/pago-remuneraciones/' + id_company]);
    }

    if (rutas[2] == 'pp-facturas-pagadas') {
      this.router.navigate(['home/pp-facturas-pagadas/' + id_company]);
    }

    if(rutas[2] == 'operaciones-remuneraciones'){
      this.router.navigate(['home/operaciones-remuneraciones/'+id_company])
    }

    if(rutas[2] == 'dashboard-compras'){
      this.router.navigate(['home/dashboard-compras/'+id_company])
    }

    if(rutas[2] == 'operaciones'){
      this.router.navigate(['home/operaciones/'+id_company])
    }

    if(rutas[2] == 'simulaciones'){
      this.router.navigate(['home/simulaciones/'+id_company])
    }

    if(rutas[2].includes('detalle-operacion')){
      this.router.navigate(['home'+id_company])
    }

    if(rutas[2].includes('cartola')){
      this.router.navigate(['home/cartola/'+id_company])
    }

    
    this.showmsg = true;
  }

  showmsg:boolean=false;
  empresa_seleccionada($e:any){

    let id_company = $e.value;

    let ruta = this.router.url

    let rutas = ruta.split("/")

    localStorage.setItem('company_id', id_company)

    this.set_storage_detail_final();

    this.empresas_relacionadas()

    if (rutas[2] == "info") 
    {
      this.router.navigate(['home/info/'+id_company])
    }

    if (rutas[2] == "new-client" || rutas[2] == undefined) 
    {
      this.router.navigate(['home/new-client/'+id_company])
    }

    if (rutas[2] == "detalle-cartera")
    {
      this.router.navigate(['home/detalle-cartera/'+id_company])
    }

    if (rutas[2] == "simulaciones")
    {
      this.router.navigate(['home/simulaciones/'+id_company])
    }

    if (rutas[2] == "liquidaciones")
    {
      this.router.navigate(['home/liquidaciones/'+id_company])
    }

    if (rutas[2] == "facturas-pagadas")
    {
      this.router.navigate(['home/facturas-pagadas/'+id_company])
    }

    if (rutas[2] == "excedentes")
    {
      this.router.navigate(['home/excedentes/'+id_company])
    }

    if (rutas[2] == "dashboard")
    {
      this.router.navigate(['home/dashboard/'+id_company])
    }

    if(rutas[2] == 'cuentas-bancarias'){
      this.router.navigate(['home/cuentas-bancarias/'+id_company])
    }

    
    if(rutas[2] == 'pago-proveedores'){
      this.router.navigate(['home/pago-proveedores/'+id_company])
    }

    if(rutas[2] == 'pago-proveedores-fechas'){
      this.router.navigate(['home/pago-proveedores-fechas/'+id_company])
    }

    if(rutas[2] == 'operaciones-proveedores'){
      this.router.navigate(['home/operaciones-proveedores/'+id_company])
    }
    
    if(rutas[2] == 'pago-remuneraciones'){
      this.router.navigate(['home/pago-remuneraciones/'+id_company])
    }

    if (rutas[2] == 'pp-facturas-pagadas') {
      this.router.navigate(['home/pp-facturas-pagadas/' + id_company]);
    }
    
    if(rutas[2] == 'operaciones-remuneraciones'){
      this.router.navigate(['home/operaciones-remuneraciones/'+id_company])
    }

    if(rutas[2] == 'dashboard-compras'){
      this.router.navigate(['home/dashboard-compras/'+id_company])
    }

    if(rutas[2] == 'operaciones'){
      this.router.navigate(['home/operaciones/'+id_company])
    }

    if(rutas[2].includes('detalle-operacion')){
      this.router.navigate(['home'+id_company])
    }

    if(rutas[2].includes('cartola')){
      this.router.navigate(['home/cartola/'+id_company])
    }



    this.showmsg = true;

  }

  TextAbstract(text:any, length:any) {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    var last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "..";
  }

  async empresas_relacionadas() {
    this.options = [];
    this.states = [];
    try {
      this.load = true;
      const bd: any = await this.settings.get_query(1, 'cliente/empresas-relacionadas/');
      var body_data: any =bd._body;
      var data = body_data.success.info;
      switch (bd.status) {
        case 200:

        console.log(body_data)
          if (data.length > 0) {
            data.forEach((item:any) => {
              this.states.push({
                id:item.company_id, name:item.business_name,rut:item.rut
              })
              this.options.push({
                id:item.company_id, name:item.business_name,rut:item.rut
              })
              this.filteredStates = this.stateCtrl.valueChanges
              .pipe(
                startWith(''),
                map((state:any) => state ? this.filterStates(state) : this.states.slice())
              );
            });
            let company_id = Number(localStorage.getItem('company_id'))
            this.selected2 = Number(localStorage.getItem('company_id'))
            let empresa = data.find((obj:any) => obj.company_id == company_id)
            if (empresa != null){
              this.myControl.setValue(empresa.business_name)
              this.company_selected = empresa.business_name;
              this.element.company_selected = (this.TextAbstract(empresa.business_name, 75)).toUpperCase();
              localStorage.setItem('business_name', (this.TextAbstract(empresa.business_name, 75)).toUpperCase())
            }
          }
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            map((value:any) => this._filter(value))
          );
          this.load = false;

          console.log("filteredOptions", this.filteredOptions)
          console.log("options", this.options)
          
        break;
        default:
          this.load = false;
        break;
      }
    } catch (bd:any) {
      this.load = false;
      this.settings.manage_errors(bd.status, "Cambiar empresa", bd);
    }
  }


  async set_storage_detail_final() {
    try {
      
      let company_id = localStorage.getItem('company_id')
      const bd: any = await this.settings.get_query(1, "clientcartera/" + company_id + "/");
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

        if (body_data.datos != null) {

          localStorage.setItem('rut', body_data.datos.rut)
          
        }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

}



export interface State {
  id: any;
  name:any,
  rut:any
  
}