<div class="row row_main">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
    <h1 class="titulo-simpli">Facturas pagadas</h1>
    <small class="subtitulo-simpli">Aquí puedes ver tus facturas de compra pagadas</small>
  </div>




  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">

    <div *ngIf="disabled" class="velo-inactivo animate__animated animate__fadeIn" style="background: #ffffffde;height: 100%;">
      <mat-spinner diameter="40" class="spinner-simpli" style="margin-top: 200px;"></mat-spinner>
      <div class="show-m">
        Sincronizando con el SII, esto puede llevar unos minutos, estamos actualizando tus facturas! :)
      </div>
    </div>

    <mat-card class="shadow-box card-tabs">
      <mat-card-content>
        <div class="w-100">
          <form [formGroup]="firstFormGroup" class="w-100" style="min-height: 375px;">
            <div class="row w-100">
              <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-top">

                <form [formGroup]="valForm">
                  <div class="row">

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <mat-form-field>
                        <mat-label>Filtrar</mat-label>
                        <input matInput formControlName="filter" (change)="reset($event.target.value)">
                        <mat-icon matTooltip="Buscar" matSuffix class="btn_suffix" (click)="filtrar()">search</mat-icon>
                        <mat-hint>Introduce una palabra clave y dale click a la lupa para filtrar.</mat-hint>
                      </mat-form-field>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <mat-form-field>
                        <mat-label>Desde</mat-label>
                        <input matInput readonly (click)="picker3.open()" [matDatepicker]="picker3"
                          (dateChange)="filter1($event)" formControlName="desde" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <mat-form-field>
                        <mat-label>Hasta</mat-label>
                        <input matInput readonly (click)="picker4.open()" [matDatepicker]="picker4"
                          (dateChange)="filter2($event)" formControlName="hasta" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                    </div>


                  </div>
                </form>

              </div>

              <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-top" style="text-align: right;">
                <button mat-flat-button class="primary-button" (click)="reset_filters()" type="button">Limpiar filtros</button>
                <button mat-flat-button class="primary-button" id="btn_llamativo" (click)="sincronizar()" type="button">Sincronizar SII</button>
              </div>

            </div>

            <div class="table-responsive-simpli add_factoring " *ngIf="infotable" style="margin-top: 20px;">



              <table mat-table [dataSource]="dataSourceFacturas" matSort (matSortChange)="sortData($event)"
                matSortActive="name" matSortDirection="asc">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef
                    matTooltip="Se marcarán todas facturas de la página en visualización, de 20 en 20.">

                    <mat-checkbox (change)="$event ? masterToggle($event) : null" (checked)="checkall"
                      formControlName="checkall" (click)="activa_final(); $event.stopPropagation()">
                    </mat-checkbox>



                  </th>
                  <td mat-cell *matCellDef="let row; let index=index;" style="text-align: center;">
                    <div style="border-radius: 3px;transition: all .3s ease-in-out;">

                      <input type="checkbox"
                        [disabled]="row.pagado || row.aceptacionReclamo == 0 || row.estado == 'Contado/Gratuito'"
                        [checked]="row.checked==true? row.checked:selection.isSelected(row)"
                        (click)="activa(row.id,index, $event, row); $event.stopPropagation()">
                    </div>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <ng-container matColumnDef="folio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Folio </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 80px;max-width: 80px;">
                    <input class="no-input" value="{{element.folio}}" readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="ultimoacreedor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Acreedor Legal </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left;min-width: 130px;">
                    <input *ngIf="element.historialAcreedores.length > 0" class="no-input"
                      [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="hover"
                      #popoverTrigger="mdePopoverTrigger" value="{{element.ultimoacreedor}}" style="    text-align: left;
                                  color: #15a83f;
                                  font-weight: 400;
                                  text-decoration: underline;
                                  cursor: pointer;" readonly>
                    <input *ngIf="element.historialAcreedores.length <= 0" class="no-input"
                      value="{{element.ultimoacreedor}}" style="text-align: left;" readonly>


                    <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false">
                      <mat-card style="max-width: 500px;padding:10px !important;background: #fafaff;"
                        class="shadow-box">
                        <mat-card-content style="text-align: center;">

                          <h1 style="font-size: 15px;
                                          color: #8581bb;
                                          text-align: left;
                                          margin-bottom: 10px;
                                          margin-top: 10px;font-weight: 400;">Historial de Acreedores</h1>

                          <table style="width: 100%;" cellspacing="0">
                            <tr style="height: 50px">
                              <td
                                style="width: 300px;text-align: left;font-weight: bold;border-bottom: solid 1px #dedede;">
                                Acreedor</td>
                              <td style="width: 300px;font-weight: bold;border-bottom: solid 1px #dedede;">Fecha</td>
                            </tr>
                            <tr style="height: 50px" *ngFor="let i of element.historialAcreedores">
                              <td style="width: 300px;text-align: left;">
                                {{i.acreedor}}
                              </td>

                              <td style="width: 300px;">
                                {{i.fecha}}
                              </td>

                            </tr>
                          </table>
                        </mat-card-content>
                      </mat-card>
                    </mde-popover>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>



                </ng-container>

                <ng-container matColumnDef="receptoracreedor__business_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Emisor Documento </th>
                  <td mat-cell *matCellDef="let element" style="text-align: left;min-width: 130px;"
                    matTooltip="{{element.receptoracreedor_name + ' - ' + element.ultimorutacreedor}}">
                    <input class="no-input" value="{{element.receptoracreedor_name}}" style="text-align: left;"
                      readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef style="text-align: center;font-weight: bold;"></td>
                </ng-container>

                <ng-container matColumnDef="mnt_total">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Monto </th>
                  <td mat-cell *matCellDef="let element" style="min-width: 130px;">
                    <input class="no-input" value="$ {{formatMoney(element.mnt_total)}}" readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef
                    style="text-align: center;font-size: 17px;font-weight: bold; color:#1b0943;"></td>
                </ng-container>

                <ng-container matColumnDef="fecha_emision">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="adjust-header"> Fecha Emisión </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                    <input class="no-input" value="{{element.fecha_emision}}" readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <ng-container matColumnDef="fechapp">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header
                    class="adjust-header">Fecha PP <i matTooltip="Agregar una fecha probable de pago es opcional"
                      style="margin-left: 10px;" class="fas fa-info-circle"></i></th>
                  <td mat-cell *matCellDef="let element; let ipp=index, let index=index"
                    style="text-align: center;cursor: pointer;min-width: 130px;">

                    <input class="no-input" placeholder="dd/mm/yyyy" (click)="ipp.open()"
                      value="{{element.fechapp_str}}" readonly />

                    <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                      <mat-label>Fecha Probable de Pago</mat-label>
                      <input [min]="minDate" matInput [matDatepicker]="ipp"
                        (dateChange)="fecha_individual_pp($event, index)" (click)="ipp.open()"
                        formControlName="thridCtrl">
                      <mat-datepicker-toggle matSuffix [for]="ipp"></mat-datepicker-toggle>
                      <mat-datepicker #ipp></mat-datepicker>
                    </mat-form-field>

                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <ng-container matColumnDef="fecha_vencimiento">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header
                    class="adjust-header">Fecha vencimiento <i matTooltip="Agregar una fecha de vencimiento es opcional"
                      style="margin-left: 10px;" class="fas fa-info-circle"></i></th>
                  <td mat-cell *matCellDef="let element; let i=index, let index=index"
                    style="text-align: center;cursor: pointer;">

                    <input [ngClass]="{'no-input':true,
                                                          'vencida':element.color === 'rojo',
                                                          'porvencer':element.color === 'blanco'}" class="no-input"
                      placeholder="dd/mm/yyyy" (click)="i.open()" value="{{element.fecha_vencimiento_str}}" readonly />

                    <mat-form-field style="visibility: hidden;width: 1px;height: 1px;position: absolute;">
                      <mat-label>Fecha de vencimiento</mat-label>
                      <input [min]="minDate" matInput [matDatepicker]="i" (dateChange)="fecha_individual($event, index)"
                        (click)="i.open()" formControlName="secondCtrl">
                      <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                      <mat-datepicker #i></mat-datepicker>
                    </mat-form-field>

                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>



                <ng-container matColumnDef="estado">
                  <th mat-header-cell *matHeaderCellDef> Estado </th>
                  <td mat-cell *matCellDef="let element;" style="min-width: 130px;">
                    <input class="no-input" value="{{element.estado}}" style="font-weight: bold;color: #1b0943;;"
                      readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>



                <ng-container matColumnDef="pagado">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 50px;text-align: center;"> Pagado </th>
                  <td mat-cell *matCellDef="let element; let index=index; let i=index;"
                    style="min-width: 70px;text-align: center;">
                    <i matTooltip="Pagado {{element.fechapagado  | date:'dd/MM/yy'}} {{element.mediopago ? element.mediopago : ''}}"
                      *ngIf="element.pagado" style="color:#3BA46F;cursor: pointer;" class="fas fa-dollar-sign"></i>
                    <i matTooltip="Marcar como Pagado" *ngIf="!element.pagado" style="color:gray;cursor: pointer;"
                      class="fas fa-dollar-sign" [mdePopoverTriggerFor]="index" mdePopoverTriggerOn="click"
                      #popoverTrigger="mdePopoverTrigger"></i>
                    <mde-popover #index="mdePopover" [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false">
                      <mat-card>
                        <mat-card-content>
                          <small>{{element.pagado ? '¿Marcar como no pagado?' : '¿Marcar como pagado?'}}</small><br>

                          <mat-form-field *ngIf="true">
                            <mat-label>Medio de pago</mat-label>
                            <mat-select [formControl]="paymentMethod">
                              <mat-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">
                                {{paymentMethod}}</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <button mat-button class="pagar-btn" type="button"
                            (click)="pagar(element.pagado, element.id, this.paymentMethod.value)">Aceptar</button>

                        </mat-card-content>
                      </mat-card>
                    </mde-popover>

                  </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- 0 es malo 1 es bueno 2 es no consultada -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 80px;text-align: center;"> Aceptación </th>
                  <td mat-cell *matCellDef="let element; let index=index; let i=index;"
                    style="min-width: 80px;text-align: center;">


                    <i style="color:#3BA46F;" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover"
                      #popoverTrigger="mdePopoverTrigger" *ngIf="element.aceptacionReclamo == 1"
                      class="fas fa-check-circle"></i>
                    <i style="color: gray;" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover"
                      #popoverTrigger="mdePopoverTrigger" *ngIf="element.aceptacionReclamo == 0"
                      class="fas fa-info-circle"></i>
                    <i style="color:gray" [mdePopoverTriggerFor]="reclamoPopover" mdePopoverTriggerOn="hover"
                      #popoverTrigger="mdePopoverTrigger"
                      *ngIf="element.aceptacionReclamo != 0 && element.aceptacionReclamo != 1"
                      class="fas fa-info-circle"></i>
                    <i style="color: gray;cursor: pointer;margin-left: 10px;"
                      matTooltip="Comprueba si está factura ha cambiado su estado de aceptación o reclamo"
                      class="fas fa-sync-alt" (click)="aceptacionReclamo(element.folio, null, null)"></i>


                    <mde-popover #reclamoPopover="mdePopover" [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false">
                      <mat-card class="shadow-box card-reclamos">
                        <mat-card-header>
                          <h1 class="card-reclamos-title">{{ element.aceptacionReclamo == 1 ? 'Aceptada':
                            element.aceptacionReclamo == 0 ? 'Rechadaza' : 'Sin información' }}</h1>
                        </mat-card-header>
                        <mat-card-content class="text-center">
                          <div *ngIf="element.reclamos.length > 0">
                            <table class="w-100" cellspacing="0">
                              <tr>
                                <td class="card-reclamos-table-header">{{ element.aceptacionReclamo == 1 ? 'Eventos':
                                  element.aceptacionReclamo == 0 ? 'Reclamos' : 'Detalles' }}</td>
                              </tr>
                              <tr *ngFor="let i of element.reclamos">
                                <td class="card-reclamos-table-td">
                                  {{i}}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mde-popover>

                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <ng-container matColumnDef="fechapagado">
                  <th mat-header-cell *matHeaderCellDef class="adjust-header"> Fecha de pago </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;"
                    matTooltip="{{element.fechapagado ? element.fechapagado : element.estado == 'Contado/Gratuito' ? 'Pago realizado al contado en fecha de emisión' : '-'}}">
                    <input class="no-input" value="{{element.fechapagado ? element.fechapagado : element.estado == 'Contado/Gratuito' ? element.fecha_emision : '-'}}"
                      readonly>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsFacturas; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsFacturas; let index=index; "></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsFacturas"></tr>

              </table>




              <div class="row" style="width: 100%;">

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-msg">
                  <small *ngIf="show_msg_acreedor_legal" style="color: #15a83f; margin-top: 20px;display: block;">*Pasa
                    el cursor sobre el Acreedor Legal para ver el Historial de Acreedores recientes.</small>
                  <!-- <small style="color: #ff933b;display: block;">*Factura por vencer.</small>
                              <small style="color: #FA454C;display: block;">*Factura vencida.</small> -->
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageCustom($event)">
                  </mat-paginator>
                </div>

              </div>


            </div>

            <div class="ol-lg-12 col-md-12 col-sm-12 col-xs-12 " *ngIf="!infotable" style="min-height: 360px;">

              <div class="sinRegistros" style="text-align: center;margin-top: 100px;">
                <small class="small-sinregistros">
                  <div style="text-align: center;margin-bottom: 20px;">
                    <i class="fa-light fa-lightbulb-exclamation-on" style="font-size: 30px;"></i>
                  </div>
                  Para visualizar información debes sincronizar tu cuenta con el SII <br>
                  ¿Deseas Sincronizar Ahora?
                  <br />
                  <br />
                  <div style="text-align: center;">
                    <button mat-flat-button class="primary-button" (click)="open_credenciales()"
                      type="button">Vamos</button>
                  </div>

                  <div style="margin-top: 22px;font-size: 13px;">
                    *Si ya sincronizaste con el SII, actualiza tus cesiones en el botón superior de la pantalla
                    <br><strong id="disable_cesion2" style="color: #1b0943;">Sincronizar Información.</strong>
                  </div>
                </small>
              </div>

            </div>





          </form>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
