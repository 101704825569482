import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardVentas } from './dashboard-ventas/dashboard-ventas';
import { Validators, FormControl } from '@angular/forms';
import { DicomComponent } from './dicom/dicom.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.less']
})
export class Dashboard implements OnInit, OnDestroy {
  showSpinner:boolean=true;
  


  @ViewChild(DashboardVentas) dash_ventas! : DashboardVentas;


  formFechas: FormGroup;

  constructor(public snackBar: MatSnackBar, private fb: FormBuilder, 
    private router: Router, public dialog: MatDialog,  
    public settings: SettingsService) { 

      
      this.formFechas = this.fb.group({
        fechaI: ['', Validators.required],
        fechaF: ['', Validators.required]
      });


    }

    dash_update(){
      this.dash_ventas.dash_update();

    }

  myObserver:any
  ngOnDestroy() {
    this.myObserver.unsubscribe();
  }

  ngOnInit() {

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
     });
     this.load_all()


  }

  async get_credenciales() {
    try {
      const bd: any = await this.settings.get_query(1, '/cliente/obtener-credenciales-sii//' + this.id_company + '/');
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:

  
                if(body_data.code == null || body_data.code == "" || body_data.code == undefined){
                  $("#disable_ventas").addClass("disable_btn_llamativo");
                }else{
                  $("#disable_ventas").removeClass("disable_btn_llamativo");
                }


          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  company_id:any;
  id_company:any;
  load_all()
  {
    this.id_company = localStorage.getItem('company_id')
    this.company_id = this.id_company;

    this.get_credenciales()

    let date = new Date()
    let yy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    this.date_end = yy+'-'+mm+'-'+dd;

    let date2 = new Date();
    date2.setMonth(date2.getMonth() - 5)
    let yy2 = date2.getFullYear();
    let mm2 = date2.getMonth()+1;
    let dd2 = date2.getDate();
    this.date_start = yy2+'-'+mm2+'-'+dd2;


    this.formFechas.controls['fechaI'].setValue(date2)
    this.formFechas.controls['fechaF'].setValue(date)


    
  }

  date_start:any;
  fecha_desde($e:any){
    let date = new Date($e.value);
    let yy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    this.date_start = yy+'-'+mm+'-'+dd;
    this.formFechas.controls['fechaI'].setValue(date)

    this.dash_ventas.ngOnInit()
  }

  date_end:any;
  fecha_hasta($e:any){
    let date = new Date($e.value);
    let yy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    this.date_end = yy+'-'+mm+'-'+dd;
    this.formFechas.controls['fechaF'].setValue(date)

    this.dash_ventas.ngOnInit()
  }

  d_btn:boolean=false;
  clear(){

    this.d_btn = true;

    let date = new Date()
    let yy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    this.date_end = yy+'-'+mm+'-'+dd;

    let date2 = new Date();
    date2.setMonth(date2.getMonth() - 5)
    let yy2 = date2.getFullYear();
    let mm2 = date2.getMonth()+1;
    let dd2 = date2.getDate();
    this.date_start = yy2+'-'+mm2+'-'+dd2;

    this.d_btn = true;

    this.formFechas.controls['fechaI'].setValue(date2)
    this.formFechas.controls['fechaF'].setValue(date)


    this.dash_ventas.ngOnInit()

    setTimeout(()=>{         
      this.d_btn = false;
    }, 6000);

  }


  dicom(){
    const dialogRef = this.dialog.open(DicomComponent,{
      width: '900px',
      autoFocus:false,
    });
    
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  





}