import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, NgForm} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/settings/settings.service';;
declare var $ :any;



@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.less']
})
export class FilesComponent implements OnInit {

  public type:any;
  public subtype:any;
  public company_id:any;
  public nombre:any;
  public pre_ruta =  ''+SettingsService.API_ENDPOINT + 'media/'
  has_file:boolean = false

  l_files:any[] = []

  showSpinner = true

  valFormCarpeta: FormGroup;

  constructor(public dialog: MatDialog, fb: FormBuilder, 
    public settings: SettingsService, 
    public snackBar: MatSnackBar) {

      this.valFormCarpeta = fb.group({
        'file_upload':[null],
      })
  }

  ngOnInit() {
    this.set_name()
    this.cargar_carpeta()
  }
  
  open_chooser()
  {
    $("#file_chooser_"+this.subtype.replace(".","")).trigger("click");
  }

  async upload_file($e:any) {
    try {

      
    let formData: FormData = new FormData();

    this.showSpinner = true
    let file_input = $e.target.files[0];

    let name = this.type + "-" + this.subtype + "-" + file_input.name
    formData.append('file', file_input, name);

      const bd: any = await this.settings.get_query(2, 'confeccionCarpeta/' + this.company_id +'/', formData);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


        this.cargar_carpeta()

        this.snackBar.open("Documentos subidos con exito", "Aceptar", {
          duration: 10000,
          panelClass: ['simpli-snack']
        });

          break;
        default:

          break;
      }
    } catch (bd: any) {

    }
  }

  set_name(){

    if (this.type == "1")
      this.pre_ruta = this.pre_ruta + "legales/"

    if (this.type == "0")
      this.pre_ruta = this.pre_ruta + "financieros/"

    if (this.type == "2")
      this.pre_ruta = this.pre_ruta + "otros/"

    switch (this.subtype) { 
      case "0.1": this.nombre="Carpeta Tributaria"
      break;
      
      case "0.2": this.nombre="Últimos Balances"
      break;

      case "0.3": this.nombre="Otros documentos tributarios"
      break;

      case "1.2": this.nombre="Consulta de Empresa en un día"
      break;

      case "1.3": this.nombre="Publicación en diario"
      break;

      case "1.4": this.nombre="Registro Comercio CBRS"
      break;

      case "1.5": this.nombre="RUT Sociedad"
      break;

      case "1.6": this.nombre="CI"
      break;

      case "1.7": this.nombre="Directorio vigente"
      break;

      case "1.20": this.nombre="Antecedente Legales"
      break;

      case "1.8": this.nombre="Informe de Sociedad"
      break;

      case "1.21": this.nombre="Contrato"
      break;
      
      case "1.22": this.nombre="Contrato Legalizado"
      break;
      
      default: this.nombre="Otros"
   }
  }

    async cargar_carpeta() {
      try {
        const bd: any = await this.settings.get_query(1, 'confeccionCarpeta/' + this.company_id +'/');
        var body_data: any =bd._body;
        switch (bd.status) {
          case 200:
  
          this.showSpinner = false

          for (let i = 0; i< body_data.confeccion_carpeta.length; i++){
            if(body_data.confeccion_carpeta[i].type.toString() == this.type){

                if(body_data.confeccion_carpeta[i].subtype == this.subtype){
                    let ruta = body_data.confeccion_carpeta[i].file;
                    let id = body_data.confeccion_carpeta[i].id;
                    let name=body_data.confeccion_carpeta[i].name;

                    let obj = {'file': name.split('-').slice(-1), 'ruta':ruta, 'id':id}

                    this.l_files.push(obj)
                }
              }
          }

          if (this.l_files.length > 0)
          {
            this.has_file = true
          }
          else
          {
            this.has_file = false
          }
  
  
            break;
          default:
  
            break;
        }
      } catch (bd: any) {
  
      }
    }

  }
