import {Component, ViewChild, OnInit, ViewContainerRef, Input, ComponentFactoryResolver} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { SettingsService } from 'src/app/settings/settings.service';;
import { FilesComponent } from '../files/files.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;

@Component({
  selector: 'documentos',
  templateUrl: "./documentos.html",
  styleUrls: ['./documentos.less']
})
export class Documentos implements OnInit {

  arr_financieros = ["0.1","0.2","0.3"]
  arr_legales = ["1.2", "1.20", "1.8", "1.21", "1.22"] //aqui siendo consticion, antecedentes legales, informe, contrato, y contrato legalizado
  arr_otros = ["2.1"]

  @Input() id_company:any;

  all_legales:any[] = [];
  all_financieros:any[] = [];
  all_otros:any[] = [];
  rol:any;
  @ViewChild('parent_files_financieros', { read: ViewContainerRef }) container_files_financieros!: ViewContainerRef;
  @ViewChild('parent_files_legales', { read: ViewContainerRef }) container_files_legales!: ViewContainerRef;
  @ViewChild('parent_files_otros', { read: ViewContainerRef }) container_files_otros!: ViewContainerRef;

  constructor(private _cfr: ComponentFactoryResolver, public dialog: MatDialog, fb: FormBuilder, 
    public settings: SettingsService, 
    public snackBar: MatSnackBar)
     {


    


  }



ngOnInit(){
  this.rol = localStorage.getItem('role');
}

empty_files()
{

 this.container_files_financieros.remove();
 this.container_files_legales.remove();
 this.container_files_otros.remove();

 for (let i = 0; i < this.all_legales.length; i++)
 {
   this.all_legales[i].destroy()
 }

 for (let i = 0; i < this.all_financieros.length; i++)
 {
   this.all_financieros[i].destroy()
 }

 for (let i = 0; i < this.all_otros.length; i++)
 {
   this.all_otros[i].destroy()
 }


}


disableAnimation = true;
ngAfterViewInit(): void {
  setTimeout(() => this.disableAnimation = false);

  setTimeout(() => {


    if(this.rol != 'admi'){
      this.arr_legales = ["1.2", "1.20", "1.8", "1.21"]
    }
  
    for (let i = 0; i < this.arr_financieros.length; i++)
    {
  
      var comp = this._cfr.resolveComponentFactory(FilesComponent);
      let dComponent = this.container_files_financieros.createComponent(comp);
  
      let s = this.arr_financieros[i].split(".")
  
      dComponent.instance.subtype = this.arr_financieros[i]
      dComponent.instance.type = s[0]
      dComponent.instance.company_id = this.id_company

  
      this.all_financieros.push(dComponent)
    }
  
   for (let i = 0; i < this.arr_legales.length; i++)
   {
  
     var comp = this._cfr.resolveComponentFactory(FilesComponent);
     let dComponent = this.container_files_legales.createComponent(comp);
  
     let s = this.arr_legales[i].split(".")
  
     dComponent.instance.subtype = this.arr_legales[i]
     dComponent.instance.type = s[0]
     dComponent.instance.company_id = this.id_company
  
     this.all_legales.push(dComponent)
    }
  
    for (let i = 0; i < this.arr_otros.length; i++)
    {
  
      var comp = this._cfr.resolveComponentFactory(FilesComponent);
      let dComponent = this.container_files_otros.createComponent(comp);
  
      let s = this.arr_otros[i].split(".")
  
      dComponent.instance.subtype = this.arr_otros[i]
      dComponent.instance.type = s[0]
      dComponent.instance.company_id = this.id_company


      this.all_otros.push(dComponent)
    }
  });


}



}
