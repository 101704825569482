<mat-expansion-panel  class="add-exp" style="position: relative" >
    <mat-expansion-panel-header class="header-exp">
        <mat-panel-title>
           <input readonly class="no-input" style="text-align: justify;cursor: pointer;color:#6c717e;" value="{{ this.nombre }} ">
        </mat-panel-title>


        <i class="material-icons" *ngIf="!has_file" style="font-size: 22px;  color:#f1bf65; margin-right: 16px;margin-top: 12px;"> info </i>
        <i class="material-icons" *ngIf="has_file"  style="font-size: 22px;  color:#3BA46F; margin-right: 16px;margin-top: 12px"> check_circle </i>
        <i  class="material-icons" style="font-size: 22px;  color: #404040; margin-right: 16px;margin-top:12px;">
            attach_file
        </i>
    </mat-expansion-panel-header>

    <div class="file-container">

        <div *ngIf="showSpinner" style="width: 100%; text-align: center;">
            <mat-spinner [diameter]="35" style="margin: 0 auto;margin-top: 20px;margin-bottom: 20px;"></mat-spinner>
        </div>
        
        <div *ngFor="let file of l_files" class="row file-detail" style="margin-bottom:7px; position: relative; width: 100%; background: #f5f5f5; border-radius: 4px; display: inline-block;">
                          
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top responfile">
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 respon-left">
                    <a href="{{ file.ruta }}" target="_blank" style="cursor:pointer; height:25px;">
                        <i class="material-icons" style="font-size: 25px; color: #484848;">
                            folder
                        </i>  
                    </a>
                    
                </div>
                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11 respon-right">
                    <span class="right-span">
                        <small class="textocajas" matTooltip="{{file.file}}">
                            <span style="font-weight: 400;">
                                {{file.file | slice:0:30}}
                            </span>
                        </small>
        

                    </span>
                    <span>
                        <a href="{{ file.ruta }}" target="_blank" matTooltip="Descargar" class="boton-descargar download" style="cursor:pointer;">
                            <i class="material-icons" style="font-size:22px; margin-top:2px;">
                                file_download
                            </i> 
                        </a>
                    </span>
                    
               

                    
                
                
                </div>
                
                
            </div>
        

        </div>

        <input type="file" id="file_chooser_{{this.subtype.replace('.','')}}" (change)="upload_file($event)" style="display: none;"/>

    </div>

    <button [disabled]="showSpinner" (click)="returnInfo()" *ngIf="nombre == 'Consulta de Empresa en un día'" matTooltip="Buscar Empresa en un Día"  mat-mini-fab color="warn" style="position: absolute; right: 70px; bottom: 15px; z-index: 1;">
        <i class="material-icons"> search </i>
    </button>

    <button (click)="open_chooser()" matTooltip="Agregar archivo" mat-mini-fab color="primary" style="position: absolute; right: 10px; bottom: 15px; z-index: 1;background: #924eff;;">
        <i class="material-icons"> add </i>
    </button>

</mat-expansion-panel>