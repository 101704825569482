<div class="row row_main bottom-responsive">

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="position: relative;">
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <span class="material-icons" (click)="return()" style="cursor: pointer;">
          keyboard_return
        </span>
      </div>
  
      
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top">
        <h1 class="titulo-simpli" style="margin-top: 0px !important;">Revisa el avance de tu operación</h1>
        <small class="subtitulo-simpli">
          <span style="margin-right: 40px;" class="block-res">N° de operación: <strong>{{id_op}}</strong></span>
          <span style="margin-right: 40px;" class="block-res">Monto total: <strong>${{formatMoney(monto_girar)}}</strong></span>
          <span class="block-res">Estado: <strong>{{status_text}}</strong></span>
        </small>
      </div>
    </div>
  </div>



  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    
    <div class="row">


        <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeInLeft animate__faster" *ngIf="loading == false">

          <div class="row" style="margin-top: 40px;">

              <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 col-top">

                <!--STEPPER-->
                <div class="row">

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top flex-responsive">
                    <div [ngClass]="{'stepper-simpli':true, 'stepper-simpli-active':step == 1}" id="step-simpli1">
                      <i class="fa-regular fa-ballot-check"></i>
                      <div class="step-text">Verificación</div>
                    </div>

                    <div class="rowline" [ngClass]="{'rowline':true, 'rowline-extended':adjuntar_archivos == true}">
                      <div class="linerow"></div>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top">

                    <div *ngIf="step == 1 && is_warning == false" [ngClass]="{'box-azul':true, 'quitar-padding':contactar_ejecutivo == true}">
                      <span class="mensaje">{{mensaje}}</span>

                      <div class="contactar-ejecutivo" style="text-align: center;" *ngIf="contactar_ejecutivo == true">
                        <button class="primary-button" mat-flat-button (click)="contactar()">
                          <mat-label>Contactar a mi ejecutivo</mat-label>
                        </button>
                      </div>

                    </div>

                    <div *ngIf="post_verificacion == true" [ngClass]="{'box-azul':true}">
                      <span class="mensaje">La operación sigue en verificación. Aun así, avanzaremos al proceso de giro. Para saber más, contacta a tu ejecutivo.</span>
                    </div>

                    <div class="box-amarillo" *ngIf="step == 1 && is_warning == true">
                      <span class="mensaje">
                        <span style="margin-right: 20px;font-size: 18px;"><i class="fa-regular fa-triangle-exclamation"></i></span> 
                        {{mensaje}}
                      </span>

                      <div *ngIf="facturas_con_problema.length > 0" style="text-align: right;margin-top: 10px;" (click)="detalle_factura()"><span style="text-decoration: underline;color:#924eff;">Abrir detalle</span> <span style="margin-left: 10px;color:#924eff;"><i class="fa-solid fa-chevron-right"></i></span></div>


                      <div class="contactar-ejecutivo" style="text-align: center;" *ngIf="contactar_ejecutivo == true && adjuntar_archivos == false">
                        <button class="primary-button" mat-flat-button (click)="contactar()">
                          <mat-label>Contactar a mi ejecutivo</mat-label>
                        </button>
                      </div>
                    </div>

                    <div class="width-res" style="width: 70%;text-align: center;margin-top: 40px;" *ngIf="adjuntar_archivos == true">

                      <span>Si tienes preguntas contacta a tu ejectuvo</span>

      
                      <div class="btn-simpli-container" style="margin-top: 30px;">
                        <button class="primary-button" style="margin: 10px 10px" mat-flat-button (click)="files()">Adjuntar</button>
                        <button  class="secondary-button"  style="margin: 10px 10px" mat-flat-button (click)="contactar()">Contactar a mi ejecutivo</button>
                      </div>
        

                    </div>

                  </div>

                </div>

                <div class="row" *ngIf="screen != 2">

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top flex-responsive">
                    <div [ngClass]="{'stepper-simpli':true, 'stepper-simpli-active':step == 2}" id="step-simpli1">
                      <i class="fa-regular fa-money-check-dollar-pen"></i>
                      <div class="step-text">Tesorería</div>
                    </div>

                    <div class="rowline">
                      <div class="linerow"></div>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top">

                    <div class="box-azul" *ngIf="step == 2">
                      <span class="mensaje">{{mensaje}}</span>
                    </div>

                  </div>

                </div>


                <div class="row" *ngIf="screen != 2">

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top flex-responsive">
                    <div [ngClass]="{'stepper-simpli':true, 'stepper-simpli-active':step == 3}" id="step-simpli1">
                      <i class="fa-regular fa-file-lines"></i>
                      <div class="step-text">Giro</div>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top">

                    <div class="box-azul" *ngIf="step == 3">
                      <span class="mensaje">{{mensaje}}</span>
                    </div>

                  </div>

                </div>

                <div class="row" *ngIf="screen == 2">

                  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 col-top flex-responsive">
                    <div [ngClass]="{'stepper-simpli':true, 'stepper-simpli-active':step == 4}" id="step-simpli1">
                      <i class="fa-regular fa-check"></i>
                      <div class="step-text">Estado final</div>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-top">

                    <div class="box-azul" *ngIf="screen == 2 && step == 4">
                      <span class="mensaje">{{mensaje}}</span>
                    </div>

                  </div>

                </div>


                  

              </div>


              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top">
                <h2 class="titulo-simpli2">Desglose de los montos</h2>

                <div class="card-simpli-cesion" style="margin-top: 40px;">
                    <mat-label class="label-interno">Información general</mat-label>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                        <mat-label class="label-izquierdo">N° Operación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho" *ngIf="id_operacion == null">{{id_sim}}</mat-label>
                        <mat-label class="label-derecho" *ngIf="id_operacion != null">{{id_operacion}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Cantidad de facturas:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{cantidad_facturas}}</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                        <mat-label class="label-izquierdo">Fecha de creación:</mat-label>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                        <mat-label class="label-derecho">{{date_created}}</mat-label>
                      </div>
                    </div>
                </div>

                <div class="card-simpli-cesion">
                  <mat-label class="label-interno">Montos asociados</mat-label>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total a ceder:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">% Financiamiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{anticipo}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto de anticipo:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{valor_anticipo_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Vencimiento:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{dias_promedio}} días</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Tasa de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">{{tasa_interes}}%</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Monto total de interés:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{interes_total}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Comisión:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Gastos operacionales:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{gastos}}</mat-label>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">IVA:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{iva_operacion}}</mat-label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2"  style="text-align: justify;">
                      <mat-label class="label-izquierdo">Excedentes:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho">${{excedentes}}</mat-label>
                    </div>
                  </div>



                </div>

                <div class="card-simpli-cesion" style="padding-top: 7px;padding-bottom: 7px;">


                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: justify;">
                      <mat-label class="label-izquierdo-monto">Monto líquido:</mat-label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col2" style="text-align: right;">
                      <mat-label class="label-derecho-monto">${{monto_recibir}}</mat-label>
                    </div>
                  </div>


                </div>

                <div class="card-simpli-cesion" style="border: none;padding:0 !important;background:none;width: 380px;">
                  <div style="margin-top: 30px;text-align: right;">
                    <mat-label *ngIf="loading_pdf_ == false" class="add_facturas" (click)="download_pdf()"><i class="fa-regular fa-download facturas-add"></i>Descargar desglose</mat-label>&nbsp;
                    <mat-label *ngIf="loading_pdf_ == true" class="add_facturas"><i class="fa-solid fa-spinner fa-spin-pulse facturas-add"></i>Descargar desglose</mat-label>&nbsp;
                  </div>
                </div>



              </div>

              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12 col-top"></div>


          </div>

        </div>


        <!--Loading-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="loading == true">

          <div class="container-loading">

            
            <div class="img-loading">
              <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
            </div>

            <div style="margin-top: 20px;">
              <mat-label class="label-loading">
                <div>La información está siendo procesada.</div>
                <div>Esto no tomará mucho tiempo.</div>
              </mat-label>
            </div>

          </div>


        </div>


    </div>




  </div>

</div>