<div class="row">

    <!--Loading-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step_to_show == null">

      <div class="container-loading">

        
        <div class="img-loading">
          <img src="../../../../assets/simpli/cesion/loading.png" class="img-loading1">
        </div>

        <div style="margin-top: 20px;">
          <mat-label class="label-loading">
            <div>La información está siendo procesada.</div>
            <div>Esto no tomará mucho tiempo.</div>
          </mat-label>
        </div>

      </div>


    </div>

    <!--nuevo & sincronizado-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step_to_show == 1">

      <div class="container-bienvenida">

        
        <div class="img-bienvenida">
          <img src="../../../../assets/simpli/cesion/bienvenido1.png" class="img-bienvenida1">
        </div>

        <div style="margin-top: 20px;">
          <mat-label class="label-bienvenida">
            <div>¡Te damos la bienvenida a nuestra plataforma para clientes!</div>
            <div>Aquí podrás acceder a todos nuestros servicios de forma Simpli</div>
          </mat-label>
        </div>

      </div>


    </div>

    <!--nuevo & NO sincronizado-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step_to_show == 2">

      <div class="container-bienvenida2">
  
        <div class="label-bienvenida"  style="padding-top: 55px;">¡Te damos la bienvenida a nuestra plataforma para clientes!</div>

        <div class="label-secundario">Sincroniza tus credenciales del SII con tu cuenta en <br> esta plataforma y accede a increíbles beneficios</div>

        <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top" style="text-align: center;">

              <div class="icon-bienvenida">
                <i class="fa-regular fa-file-magnifying-glass"></i>
              </div>

              <div class="text-bienvenida">
                Visualiza la información <br> actualizada de tus facturas
              </div>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top" style="text-align: center;">

              <div class="icon-bienvenida">
                <i class="fa-regular fa-handshake"></i>
              </div>

              <div class="text-bienvenida">
                Cede directamente por <br> esta plataforma
              </div>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-top" style="text-align: center;">

              <div class="icon-bienvenida">
                <i class="fa-regular fa-chart-mixed"></i>
              </div>

              <div class="text-bienvenida">
                Revisa tu Dashboard financiero <br> y contable
              </div>

            </div>

        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" (click)="openDialog()" mat-flat-button>Sincronizar credenciales</button>

              <button class="secondary-button" (click)="onClick()" mat-flat-button>Hacerlo más tarde</button>
            </div>
            
          </div>
        </div>
  
      </div>
  
  
    </div>

    <!--viejo & sincronizado & sin automaticas-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step_to_show == 3">

      <div class="container-bienvenida3">

        <div style="margin-bottom: 20px;padding-top: 40px;">
          <mat-label class="label-bienvenida">
            <div>¿Quieres comenzar hoy? Déjanos ayudarte</div>
          </mat-label>
        </div>

        
        <div class="img-bienvenida">
          <img src="../../../../assets/simpli/cesion/bienvenida3.png" class="img-bienvenida1">
        </div>

        <div style="margin-top: 40px;">
          <mat-label class="label-bienvenida">
            <div>Permite que gestionemos el pago a proveedores</div>
            <div>o simula una operación antes de ceder. ¡Así de Simpli!</div>
          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" (click)="pagar_proveedores()" mat-flat-button>Pagar a proveedores</button>

              <button class="secondary-button" (click)="simular()" mat-flat-button>Simular operación</button>
            </div>
            
          </div>
        </div>

      </div>


    </div>

    <!--viejo & sincronizado & con automaticas-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn" *ngIf="step_to_show == 4">

      <div class="container-bienvenida3">


        <div class="img-bienvenida" style="padding-top:50px">
          <img src="../../../../assets/simpli/cesion/bienvenido2.png" class="img-bienvenida1" style="margin-right: 0px;">
        </div>

        <div style="margin-top: 40px;">
          <mat-label class="label-bienvenida">
            <div>¡Nos alegra tenerte de regreso!</div>
            <div>Simula una operación hoy mismo o navega por las</div>
            <div>demás opciones de la plataforma</div>
          </mat-label>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="btn-simpli-container">
              <button class="primary-button" (click)="go_to_simulaciones()" mat-flat-button>Ver simulaciones</button>

              <button class="secondary-button" (click)="simular()" mat-flat-button>Simular Operación</button>
            </div>
            
          </div>
        </div>

      </div>


    </div>

    <!--operaciones tab-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn responsive-simpli" *ngIf="step_to_show == 5">

      <app-detalle-operaciones [id_company]="id_company" [parent]="this"></app-detalle-operaciones>

    </div>

</div>